import React, {useState} from 'react';

import {gql, useMutation, useQuery} from "@apollo/client";
import Form from "react-bootstrap/Form";
import {Button, Modal, Table} from "react-bootstrap";

import {Redirect} from 'react-router-dom';
import Loading from "../../components/loading/Loading";
import {withApollo} from "@apollo/client/react/hoc";

const GET_PARTICIPANTS = gql`
    query Participants($project:ID!) {
        contacts(project: $project) {
            _id
            firstname
            lastname
            email
            tel
            funktion
        }
    }
`;

const CREATE_CONTACT = gql`
    mutation CreateContact($project:ID!, $firstname:String!, $lastname: String!, $email: String, $tel: String, $funktion: String, $_id: ID) {
        createContact(project: $project, firstname: $firstname, lastname: $lastname, email: $email, tel: $tel, funktion: $funktion, _id: $_id)
    }
`;

const DELETE_CONTACT = gql`
    mutation DeleteContact($_id:ID!,$project: ID!) {
        deleteContact(_id: $_id, project: $project)
    }
`;

const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

const setState = (setFunc: any, value: any) => {
    setFunc(value);
};

function Contacts(props: any) {
    const {loading, error, data, refetch} = useQuery(GET_PARTICIPANTS, {variables: {project: props.id}});

    const [createProtocol, {}] = useMutation(CREATE_CONTACT);
    const [deleteContact, {}] = useMutation(DELETE_CONTACT);

    const [showProtocol, setShowProtocol] = useState(false);
    const handleCloseProtocol = () => {
        if(id) {
            setEmail("");
            setFirstname("");
            setLastname("");
            setTel("");
            setFunktion("")
            setID(null);
        }
        setShowProtocol(false)
    };
    const handleShowProtocol = () => setShowProtocol(true);
    const handleSaveProtocol = (e: any) => {
        e.preventDefault();
        setShowProtocol(false);
        if (id) {
            createProtocol({
                variables: {
                    project: props.id,
                    _id: id,
                    firstname,
                    lastname,
                    email,
                    tel,
                    funktion
                }
            }).then(r => {
                refetch();
                setEmail("");
                setFirstname("");
                setLastname("");
                setTel("");
                setFunktion("")
                setID(null);
            });
        } else {
            createProtocol({
                variables: {
                    project: props.id,
                    firstname,
                    lastname,
                    email,
                    tel,
                    funktion
                }
            }).then(r => {
                refetch();
                setEmail("");
                setFirstname("");
                setLastname("");
                setTel("");
                setFunktion("")
            });
        }
    };

    const [id, setID] = useState(null);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [funktion, setFunktion] = useState('');


    //
    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <Redirect to="/projects"/>
    }

    return (
        <>
            <Button variant="outline-primary" onClick={handleShowProtocol} block>
                Neuen Kontakt hinzufügen
            </Button>
            <br/>
            <Table striped hover>
                <thead>
                <tr>
                    <th>Nachname</th>
                    <th>Vorname</th>
                    <th>Funktion</th>
                    <th>E-Mail</th>
                    <th>Telefon</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {data.contacts.map((participant: any) => {
                    return (
                        <tr key={participant._id}>
                            <th>{participant.lastname}</th>
                            <td>{participant.firstname}</td>
                            <td>{participant.funktion}</td>
                            <td>{participant.email}</td>
                            <td style={{whiteSpace: 'pre-wrap'}}>{participant.tel}</td>
                            <td><Button variant="outline-danger" onClick={(e: any) => {
                                deleteContact({
                                    variables: {
                                        _id: participant._id,
                                        project: props.id
                                    }
                                });
                                refetch();
                            }}>Entfernen</Button>
                                <Button variant="outline-primary" style={{marginLeft: "10px"}} onClick={(e: any) => {
                                    setID(participant._id);
                                    setEmail(participant.email);
                                    setFirstname(participant.firstname);
                                    setLastname(participant.lastname);
                                    setTel(participant.tel);
                                    setFunktion(participant.funktion)
                                    handleShowProtocol()
                                }}>Bearbeiten</Button></td>
                        </tr>)
                })}
                </tbody>
            </Table>

            <Modal show={showProtocol} onHide={handleCloseProtocol}>
                <Form onSubmit={handleSaveProtocol}>
                    <Modal.Header closeButton>
                        <Modal.Title>{id ? "Kontakt bearbeiten" : "Neuen Kontakt hinzufügen"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Nachname</Form.Label>
                            <Form.Control required type="text" defaultValue={lastname}
                                          onInput={(e: any) => setInput(e, setLastname)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Vorname</Form.Label>
                            <Form.Control type="text" defaultValue={firstname}
                                          onInput={(e: any) => setInput(e, setFirstname)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Funktion</Form.Label>
                            <Form.Control type="text" defaultValue={funktion}
                                          onInput={(e: any) => setInput(e, setFunktion)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" defaultValue={email}
                                          onInput={(e: any) => setInput(e, setEmail)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Telefon</Form.Label>
                            <Form.Control as="textarea" rows={3} defaultValue={tel}
                                          onInput={(e: any) => setInput(e, setTel)}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProtocol}>
                            Schließen
                        </Button>
                        <Button variant="primary" type="submit">
                            Speichern
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    );
}

export default withApollo(Contacts);
