import React, {useState} from 'react';

import {gql, useMutation, useQuery} from "@apollo/client";
import Form from "react-bootstrap/Form";
import {Alert, Button, Card, InputGroup, Modal} from "react-bootstrap";
import Loading from "../../components/loading/Loading";
import {withApollo} from "@apollo/client/react/hoc";
import {getJWT} from "../../App";
import {BACKEND_URL} from "../../const";
import {addDays, fromUnixTime, getUnixTime} from 'date-fns'

const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

const GET_TASKS = gql`
    query Courses($project:ID!) {
        courses(project: $project) {
            _id
            date
            start
            end
            startBreak
            endBreak
            category
            processor
            subject
            note
            kursNr
        }
    }
`;

const CREATE_TASK = gql`
    mutation CreateCourse($project: ID, $date: String!, $start: String!, $end: String!, $startBreak: String!, $endBreak: String!, $subject: String, $note: String, $processor: String, $category: String, $_id: ID, $kursNr: Int) {
        createCourse(project: $project, date: $date, start: $start, end: $end, startBreak: $startBreak, endBreak: $endBreak, subject: $subject, note: $note, processor: $processor, category: $category, _id: $_id, kursNr: $kursNr)
    }
`;

const DELETE_COURSE = gql`
    mutation DeleteCourse($_id: ID!, $project: ID!) {
        deleteCourse(_id: $_id, project: $project)
    }
`;


const GET_USERS = gql`
    {
        users {
            firstname
            lastname
            email
        }
    }
`;

const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

const setState = (setFunc: any, value: any) => {
    setFunc(value);
};


const timeExp = new RegExp("^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$", "g");

const timeCheck = (e: any, setFunc: any, invalFunc: any) => {
    const groups = e.target.value.matchAll(timeExp);
    let matches;
    for (const match of groups) {
        matches = match;
    }
    const n = matches?.map((match: string, k: number) => {
        if (k !== 0) {
            if (match.length === 1) {
                return "0" + match;
            } else return match;
        }
        return match;
    });
    if (n?.length === 3) {
        setState(setFunc, n[1] + ":" + n[2]);
    }
    invalFunc(matches?.length !== 3);

};

const getStartTime = (ins: string, n: number) => {
    if (ins === "Kita" || ins === "Vorschule") {
        return n === 1 ? "09:00" : "10:30";
    } else if (ins === "Grundschule") {
        return n === 1 ? "14:30" : "16:15"
    } else return "";
};

const getEndTime = (ins: string, n: number) => {
    if (ins === "Kita" || ins === "Vorschule") {
        return n === 1 ? "10:30" : "12:00";
    } else if (ins === "Grundschule") {
        return n === 1 ? "16:00" : "17:45"
    } else return "";
};

const getStartBreakTime = (ins: string, n: number) => {
        return n === 1 ? "09:45" : "11:15";
};

const getEndBreakTime = (ins: string, n: number) => {
        return n === 1 ? "10:00" : "11:30";
};

function Courses(props: any) {
    const resetModal = () => {
        setShowTask(false);
        setDate("");
        setStart(getStartTime(props.institution, 1));
        setEnd(getEndTime(props.institution, 1));
        setCategory("Kurs");
        setSubject("");
        setNote("");
        setStartInvalid(false);
        setEndInvalid(false);
        setProcessor(jwt.payload.firstname + " " + jwt.payload.lastname);
        setId("");
        setEdit(false);
        setKursNr("");
    };

    const {loading, error, data, refetch} = useQuery(GET_TASKS, {variables: {project: props.id}});
    const {loading: loadingUsers, error: errorUsers, data: dataUsers} = useQuery(GET_USERS);

    const [createTask, {}] = useMutation(CREATE_TASK);
    const [deleteCourse, {}] = useMutation(DELETE_COURSE);

    const [showTask, setShowTask] = useState(false);
    const [showDefault, setShowDefault] = useState(false);
    const [showKurslistenHerunterladen, setShowKurslistenHerunterladen] = useState(false);
    const [showUrkundenHerunterladen, setShowUrkundenHerunterladen] = useState(false);
    const [kurslisteVon, setKurslisteVon] = useState("1");
    const [kurslisteBis, setKurslisteBis] = useState("1");
    const [urkundeVon, setUrkundeVon] = useState("1");
    const [urkundeBis, setUrkundeBis] = useState("1");

    const handleCloseTask = () => {
        resetModal();
        setShowTask(false)
    };
    const handleCloseDefault = () => {
        resetModal();
        setShowDefault(false)
    };
    const handleShowTask = () => setShowTask(true);
    const handleSaveTask = (e: any) => {
        e.preventDefault();
        if (!startInvalid && !endInvalid && !startBreakInvalid && !endBreakInvalid) {
            setShowTask(false);
            const v: any = {
                project: props.id,
                date: Date.parse(date).toString(),
                start,
                end,
                startBreak,
                endBreak,
                category,
                subject,
                note,
                processor,
                kursNr: parseInt(kursNr)
            };
            if (id) {
                v._id = id;
            }
            createTask({
                variables: v
            }).then(r => {
                resetModal();
                refetch();
            });
        }

    };

    const jwt: any = getJWT();

    const [start, setStart] = useState(getStartTime(props.institution, 1));
    const [startInvalid, setStartInvalid] = useState(false);
    const [end, setEnd] = useState(getEndTime(props.institution, 1));
    const [endInvalid, setEndInvalid] = useState(false);

    const [startBreak, setStartBreak] = useState(getStartBreakTime(props.institution, 1));
    const [startBreakInvalid, setStartBreakInvalid] = useState(false);
    const [endBreak, setEndBreak] = useState(getEndBreakTime(props.institution, 1));
    const [endBreakInvalid, setEndBreakInvalid] = useState(false);

    const [note, setNote] = useState('');
    const [subject, setSubject] = useState('');
    const [kursNr, setKursNr] = useState("");
    const [date, setDate] = useState('');
    const [category, setCategory] = useState('Kurs');
    const [processor, setProcessor] = useState(jwt.payload.firstname + " " + jwt.payload.lastname);
    const [id, setId] = useState('');
    const [edit, setEdit] = useState(false);


    const [d1, setD1] = useState("");
    const [d1Invalid, setD1Invalid] = useState(false);

    if (loading || loadingUsers) {
        return <Loading/>
    }
    if (error || errorUsers) {
        // return <Redirect to="/projects"/>
    }


    let cards: any = {};
    data.courses.forEach((task: any) => {
        if (!cards[task.date] && !isNaN(task.date)) {
            cards[task.date] = [];
        }
        if (!isNaN(task.date)) {
            cards[task.date].push(task);
        }
    });

    const createDefaultTimes = async () => {
        await createTask({
            variables: {
                project: props.id,
                date: Date.parse(d1).toString(),
                start: getStartTime(props.institution, 1),
                end: getEndTime(props.institution, 1),
                startBreak: getStartBreakTime(props.institution, 1),
                endBreak: getEndBreakTime(props.institution, 1),
                category: "Kurs",
                subject: '',
                note: '',
                processor: jwt.payload.firstname + " " + jwt.payload.lastname
            }
        });
        await createTask({
            variables: {
                project: props.id,
                date: Date.parse(d1).toString(),
                start: getStartTime(props.institution, 2),
                end: getEndTime(props.institution, 2),
                startBreak: getStartBreakTime(props.institution, 2),
                endBreak: getEndBreakTime(props.institution, 2),
                category: "Kurs",
                subject: '',
                note: '',
                processor: jwt.payload.firstname + " " + jwt.payload.lastname
            }
        });
        await createTask({
            variables: {
                project: props.id,
                date: (getUnixTime(addDays(fromUnixTime(Date.parse(d1) / 1000), 1)) * 1000).toString(),
                start: getStartTime(props.institution, 1),
                end: getEndTime(props.institution, 1),
                startBreak: getStartBreakTime(props.institution, 1),
                endBreak: getEndBreakTime(props.institution, 1),
                category: "Kurs",
                subject: '',
                note: '',
                processor: jwt.payload.firstname + " " + jwt.payload.lastname
            }
        });
        await createTask({
            variables: {
                project: props.id,
                date: (getUnixTime(addDays(fromUnixTime(Date.parse(d1) / 1000), 1)) * 1000).toString(),
                start: getStartTime(props.institution, 2),
                end: getEndTime(props.institution, 2),
                startBreak: getStartBreakTime(props.institution, 2),
                endBreak: getEndBreakTime(props.institution, 2),
                category: "Kurs",
                subject: '',
                note: '',
                processor: jwt.payload.firstname + " " + jwt.payload.lastname
            }
        });
        refetch();
    };

    cards = Object.values(cards).sort((x: any, y: any) =>
        x[0].date - y[0].date
    )

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <Button variant="outline-primary" onClick={handleShowTask} block>Neuen Termin erstellen</Button>
                </div>
                <div className="col-6">
                    <Button variant="outline-primary" onClick={() => setShowKurslistenHerunterladen(true)} block>Kurslisten
                        herunterladen</Button>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-6">
                    {props.institution && <Button variant="outline-primary" onClick={() => setShowDefault(true)} block>Standardtermine
                        erstellen</Button>}                   </div>
                <div className="col-6">
                    <Button variant="outline-primary" onClick={() => setShowUrkundenHerunterladen(true)} block>Urkunden
                        herunterladen</Button>
                </div>
            </div>
            <br/>
            {
                cards.map((course: any, k: number) => {
                    if (k === 1) {
                        return;
                    }
                    const date = new Date(parseInt(course[0].date));
                    const firstDateFormatted = date.toLocaleDateString('de-DE', options);
                    let timeFormatted = firstDateFormatted;
                    let secondDateFormatted = "";
                    if (k === 0 && cards[1] != null) {
                        const secondDate = new Date(parseInt(cards[1][0].date));
                        secondDateFormatted = secondDate.toLocaleDateString('de-DE', options);
                        timeFormatted += " & " + secondDateFormatted
                    }
                    const c = 1;
                    const n = 1;
                    return (
                        <div key={k}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{timeFormatted}</Card.Title>
                                    <hr/>
                                    {k === 0 && cards[1] != null && (
                                        <div>
                                            <b>{firstDateFormatted}:</b>
                                            <hr/>
                                        </div>
                                    )}
                                    {course.map((entry: any, key: number) => {
                                        return (
                                            <Form key={key}>
                                                <Form.Group>
                                                    <Form.Label>{entry.kursNr ? entry.category + " - Nr.: " + entry.kursNr : entry.category}</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text
                                                                id="basic-addon2">{entry.processor}</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control type="text"
                                                                      value={entry.start + " - " + entry.end}
                                                                      readOnly
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text className="email" id="basic-addon2"
                                                                             onClick={() => {
                                                                                 setEdit(true);
                                                                                 setStart(entry.start);
                                                                                 setEnd(entry.end);
                                                                                 setStartBreak(entry.startBreak ? entry.startBreak : getStartBreakTime(props.institution, entry.kursNr ? entry.kursNr : 1));
                                                                                 setEndBreak(entry.endBreak ? entry.endBreak : getEndBreakTime(props.institution, entry.kursNr ? entry.kursNr : 1));
                                                                                 setDate(new Date(parseInt(entry.date)).toISOString().slice(0, 10));
                                                                                 setCategory(entry.category);
                                                                                 setSubject(entry.subject);
                                                                                 setNote(entry.note);
                                                                                 setId(entry._id);
                                                                                 setKursNr(entry.kursNr);
                                                                                 setShowTask(true);
                                                                                 setProcessor(entry.processor);
                                                                             }}>Bearbeiten</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                    <Form.Text className="text-muted">
                                                        {entry.subject}
                                                    </Form.Text>
                                                </Form.Group>
                                                <hr/>
                                                <Button variant="outline-primary" onClick={() => {
                                                    window.open(BACKEND_URL + "course-list/" + entry._id, "_blank")
                                                }}>Kursliste für Trainer herunterladen</Button>
                                                <Button variant="outline-primary" style={{marginLeft: "10px"}}
                                                        onClick={() => {
                                                            window.open(BACKEND_URL + "course-list-pdf/" + entry._id, "_blank")
                                                        }}>Kursliste für Einrichtung herunterladen</Button>
                                                <Button variant="outline-primary" style={{marginLeft: "10px"}}
                                                        onClick={() => {
                                                            window.open(BACKEND_URL + "certificate/" + entry._id, "_blank")
                                                        }}>Urkunden herunterladen</Button>
                                                <hr/>
                                            </Form>
                                        )
                                    })}
                                    {k === 0 && cards[1] != null && (
                                        <div>
                                            <b>{secondDateFormatted}:</b>
                                            <hr/>
                                            {cards[1].map((entry: any, key: number) => {
                                                return (
                                                    <Form key={key}>
                                                        <Form.Group>
                                                            <Form.Label>{entry.kursNr ? entry.category + " - Nr.: " + entry.kursNr : entry.category}</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text
                                                                        id="basic-addon2">{entry.processor}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text"
                                                                              value={entry.start + " - " + entry.end}
                                                                              readOnly
                                                                />
                                                                <InputGroup.Append>
                                                                    <InputGroup.Text className="email" id="basic-addon2"
                                                                                     onClick={() => {
                                                                                         setEdit(true);
                                                                                         setStart(entry.start);
                                                                                         setEnd(entry.end);
                                                                                         setStartBreak(entry.startBreak ? entry.startBreak : getStartBreakTime(props.institution, entry.kursNr ? entry.kursNr : 1));
                                                                                         setEndBreak(entry.endBreak ? entry.endBreak : getEndBreakTime(props.institution, entry.kursNr ? entry.kursNr : 1));
                                                                                         setDate(new Date(parseInt(entry.date)).toISOString().slice(0, 10));
                                                                                         setCategory(entry.category);
                                                                                         setSubject(entry.subject);
                                                                                         setNote(entry.note);
                                                                                         setId(entry._id);
                                                                                         setKursNr(entry.kursNr);
                                                                                         setShowTask(true);
                                                                                         setProcessor(entry.processor);
                                                                                     }}>Bearbeiten</InputGroup.Text>
                                                                </InputGroup.Append>
                                                            </InputGroup>
                                                            <Form.Text className="text-muted">
                                                                {entry.subject}
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <hr/>
                                                        <Button variant="outline-primary" onClick={() => {
                                                            window.open(BACKEND_URL + "course-list/" + entry._id, "_blank")
                                                        }}>Kursliste für Trainer herunterladen</Button>
                                                        <Button variant="outline-primary" style={{marginLeft: "10px"}}
                                                                onClick={() => {
                                                                    window.open(BACKEND_URL + "course-list-pdf/" + entry._id, "_blank")
                                                                }}>Kursliste für Einrichtung herunterladen</Button>
                                                        <Button variant="outline-primary" style={{marginLeft: "10px"}}
                                                                onClick={() => {
                                                                    window.open(BACKEND_URL + "certificate/" + entry._id, "_blank")
                                                                }}>Urkunden herunterladen</Button>
                                                        <hr/>
                                                    </Form>
                                                )
                                            })}
                                        </div>
                                    )}
                                    <Card.Text style={{whiteSpace: 'pre-wrap'}}>
                                        {course.processor}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <br/>
                        </div>
                    );
                })
            }
            <Modal show={showTask} onHide={handleCloseTask}>
                <Form onSubmit={handleSaveTask}>
                    <Modal.Header closeButton>
                        <Modal.Title>{edit ? "Termin bearbeiten" : "Neuen Termin erstellen"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Datum</Form.Label>
                            <Form.Control type="date" required defaultValue={date}
                                          onInput={(e: any) => setInput(e, setDate)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Von</Form.Label>
                            <Form.Control required type="text" defaultValue={start} placeholder="08:30"
                                          isInvalid={startInvalid}
                                          onBlur={(e: any) => timeCheck(e, setStart, setStartInvalid)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Bis</Form.Label>
                            <Form.Control required type="text" defaultValue={end} placeholder="10:30"
                                          isInvalid={endInvalid}
                                          onBlur={(e: any) => timeCheck(e, setEnd, setEndInvalid)}/>
                        </Form.Group>
                        <hr/>
                        <Form.Group controlId="exampleForm.ControlSelect2">
                            <Form.Label>Durchführer</Form.Label>
                            <Form.Control as="select" value={processor} required
                                          onChange={(e: any) => setInput(e, setProcessor)}>
                                {dataUsers.users.map((user: any) =>
                                    <option
                                        key={user.email}
                                        value={user.firstname + " " + user.lastname}>{user.firstname + " " + user.lastname}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Kategorie</Form.Label>
                            <Form.Control as="select" defaultValue={category} required
                                          onChange={(e: any) => setInput(e, setCategory)}>
                                <option value="Kurs">Kurs</option>
                                <option value="Elternveranstaltung">Elternveranstaltung</option>
                                <option value="Informationsveranstaltung">Informationsveranstaltung</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Kurs-Nr.</Form.Label>
                            <Form.Control type="number" defaultValue={kursNr}
                                          onInput={(e: any) => setInput(e, setKursNr)}/>
                        </Form.Group>
                        {props.institution === 'Kita' && category === 'Kurs' && <>
                            <hr/>
                            <Form.Group>
                                <Form.Label>Pause von</Form.Label>
                                <Form.Control required type="text" defaultValue={startBreak} placeholder="09:45"
                                              isInvalid={startBreakInvalid}
                                              onBlur={(e: any) => timeCheck(e, setStartBreak, setStartBreakInvalid)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Pause bis</Form.Label>
                                <Form.Control required type="text" defaultValue={endBreak} placeholder="10:00"
                                              isInvalid={endBreakInvalid}
                                              onBlur={(e: any) => timeCheck(e, setEndBreak, setEndBreakInvalid)}/>
                            </Form.Group>
                            <hr/>
                        </>}
                        <Form.Group>
                            <Form.Label>Betreff</Form.Label>
                            <Form.Control type="text" defaultValue={subject}
                                          onInput={(e: any) => setInput(e, setSubject)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Notiz</Form.Label>
                            <Form.Control type="text" defaultValue={note}
                                          onInput={(e: any) => setInput(e, setNote)}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseTask}>
                            Schließen
                        </Button>
                        {edit && <Button variant="danger" onClick={() => {
                            resetModal();
                            deleteCourse({variables: {_id: id, project: props.id}});
                            refetch();
                        }
                        }>
                            Löschen
                        </Button>}
                        <Button variant="primary" type="submit">
                            Speichern
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal show={showDefault} onHide={handleCloseDefault}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Standardtermine erstellen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant="info">
                            Es werden Kurse mit den Standardzeiten (je nach aktueller Einrichtung) erstellt.
                            <hr/>
                            Bei "Kurse neu erstellen" werden alle bisherigen Kurse gelöscht und die neuen Kurse an dem
                            von Ihnen spezifizierten Tag erstellt.
                            <hr/>
                            Bei Kurse hinzufügen werden neue Kurse unter Beibehalt der bisherigen hinzugefügt.
                        </Alert>
                        <Form.Group>
                            <Form.Label>Datum</Form.Label>
                            <Form.Control type="date" required isInvalid={d1Invalid}
                                          onInput={(e: any) => setInput(e, setD1)}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDefault}>
                            Schließen
                        </Button>
                        <Button variant="warning" onClick={(e: any) => {
                            if (d1 !== "") {
                                setD1Invalid(false);
                                setShowDefault(false);
                                data.courses.forEach((c: any) => deleteCourse({
                                    variables: {
                                        _id: c._id,
                                        project: props.id
                                    }
                                }));
                                createDefaultTimes();
                                setD1("");
                            } else setD1Invalid(true);

                        }}>
                            Kurse neu erstellen
                        </Button>
                        <Button variant="primary" onClick={(e: any) => {
                            if (d1 !== "") {
                                setD1Invalid(false);
                                setShowDefault(false);
                                createDefaultTimes();
                                setD1("");
                            } else setD1Invalid(true);

                        }}>
                            Kurse hinzufügen
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal show={showKurslistenHerunterladen} onHide={() => setShowKurslistenHerunterladen(false)}>
                <Form onSubmit={handleSaveTask}>
                    <Modal.Header closeButton>
                        <Modal.Title>Kurslisten herunterladen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Von Kurs-Nr.:</Form.Label>
                            <Form.Control type="number" min="1" value={kurslisteVon}
                                          onChange={(e: any) => {
                                              setKurslisteVon(e.target.value);
                                              setKurslisteBis(e.target.value);
                                          }}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Bis</Form.Label>
                            <Form.Control type="number" value={kurslisteBis}
                                          onChange={(e: any) => setInput(e, setKurslisteBis)}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowKurslistenHerunterladen(false)}>
                            Schließen
                        </Button>
                        <Button variant="primary" onClick={() => {
                            setShowKurslistenHerunterladen(false);
                            window.open(BACKEND_URL + `course-lists/${props.id}/${kurslisteVon}/${kurslisteBis}`, "_blank")
                        }}>
                            Herunterladen
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal show={showUrkundenHerunterladen} onHide={() => setShowUrkundenHerunterladen(false)}>
                <Form onSubmit={handleSaveTask}>
                    <Modal.Header closeButton>
                        <Modal.Title>Urkunden herunterladen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Von Kurs-Nr.:</Form.Label>
                            <Form.Control type="number" placeholder="1" min="1" value={urkundeVon}
                                          onChange={(e: any) => {
                                              setInput(e, setUrkundeVon);
                                              setInput(e, setUrkundeBis);
                                          }}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Bis</Form.Label>
                            <Form.Control type="number" placeholder="3" value={urkundeBis}
                                          onChange={(e: any) => setInput(e, setUrkundeBis)}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowUrkundenHerunterladen(false)}>
                            Schließen
                        </Button>
                        <Button variant="primary" onClick={() => {
                            setShowUrkundenHerunterladen(false);
                            window.open(BACKEND_URL + `certificates/${props.id}/${urkundeVon}/${urkundeBis}`, "_blank")
                        }}>
                            Herunterladen
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export {getStartTime, getEndTime}
export default withApollo(Courses);
