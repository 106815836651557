import React, {useContext} from 'react';

import Button from 'react-bootstrap/Button';

import Form from "react-bootstrap/Form";
import {Nav, Navbar} from "react-bootstrap";

import {LoggedIn} from "../../App";

function Topbar(props: any) {
    const {setLoggedIn} = useContext(LoggedIn);

    return (
        <div>
            <Navbar bg="dark" expand="lg" variant="dark">
                <Navbar.Brand href="/dashboard">Smart-Team</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/tasks">Meine Aufgaben</Nav.Link>
                        <Nav.Link href="/projects">Projekte</Nav.Link>
                        <Nav.Link href="/online-courses">Online-Kurse</Nav.Link>
                        <Nav.Link href="/settings">Einstellungen</Nav.Link>
                    </Nav>
                    <Form inline>
                        <Button variant="outline-warning" onClick={() => setLoggedIn(false)}>Abmelden</Button>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
            {props.children}
        </div>
    );
}

export default Topbar;
