import React, {useState} from 'react';

import {gql, useMutation, useQuery} from "@apollo/client";
import {Button, Card, Container, FormControl, Table} from "react-bootstrap";

import {Redirect, withRouter} from "react-router-dom";

import './ProjectList.css';
import {useFilters, useGlobalFilter, useTable} from 'react-table'
import Loading from "../../components/loading/Loading";

const options = {year: 'numeric', month: '2-digit', day: '2-digit'};

const GENERATE_JWT = gql`
    mutation GcenerateJWT($email:String!, $password: String!) {
        generateJWT(email: $email, password: $password)
    }
`;

const CREATE_PROJECT = gql`
    mutation createProject($institution: String, $name: String, $address: InputAddress, $tel: String, $email: String, $laufzettel: String, $meetingPoint: String, $followUp: Int, $notes: String, $_id: ID) {
        createProject(institution: $institution, name: $name, address: $address, tel: $tel, email: $email, laufzettel: $laufzettel, meetingPoint: $meetingPoint, followUp: $followUp, notes: $notes, _id: $_id)
    }
`;

// Define a default UI for filtering
function DefaultColumnFilter({
                                 column: {filterValue, preFilteredRows, setFilter},
                             }: { column: any }) {
    const count = preFilteredRows.length;

    return (
        <FormControl
            value={filterValue || ''}
            onChange={(e: any) => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            /*placeholder={`Search ${count} records...`}*/
        />
    )
}

// Our table component
function FilterTable({columns, data, history, match, slices}: any) {
    const filterTypes: any = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: '',
            // Or, override the default text filter to use
            // "startWith"
            text: (rows: any, id: any, filterValue: any) => {
                return rows.filter((row: any) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    );

    const defaultColumn: any = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
    }: any = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
        },
        useFilters, // useFilters!
        useGlobalFilter // useGlobalFilter!
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    const firstPageRows = rows.slice(0, slices);

    return (
        <>
            <Table className="Table-rounded" bordered striped hover {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup: any, i: number) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            column.canFilter ? <th {...column.getHeaderProps()}>
                                <Card.Title>
                                    {column.render('Header')}
                                </Card.Title>
                                {/* Render the columns filter UI */}
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </th> : <></>
                        ))}
                    </tr>
                ))}
                <tr>

                    <th
                        colSpan={visibleColumns.length}
                        style={{
                            textAlign: 'left',
                        }}
                    >
                    </th>
                </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                {firstPageRows.map((row: any, i: any) => {
                    prepareRow(row);
                    return (
                        <tr key={i} className="tr" {...row.getRowProps()}>
                            {row.cells.map((cell: any, cellI: number) => {
                                return <td key={cellI} {...cell.getCellProps()}><a href={"/projects/" + row.original._id} style={{color: "black", textDecoration: "none"}}>{cell.render('Cell')}</a></td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </Table>
            <br/>
        </>
    )
}

function ProjectList(props: any) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Projekte',
                columns: [
                    {
                        Header: 'Jahr',
                        accessor: 'year',
                    },
                    {
                        Header: 'Einrichtung',
                        accessor: 'institution',
                    },
                    {
                        Header: 'Name',
                        accessor: 'name',
                    },
                    {
                        Header: 'Teilnehmer',
                        accessor: 'participants',
                    },
                    {
                        Header: 'Bundesland',
                        accessor: 'state',
                    },
                    {
                        Header: 'Ort',
                        accessor: 'city',
                    },
                    {
                        Header: 'Straße',
                        accessor: 'street',
                    },
                    {
                        Header: '1. Kurstag',
                        accessor: 'firstDay',
                    },
                    {
                        Header: 'ID',
                        accessor: 'id',
                    },
                ],
            }
        ],
        []
    );


    const GET_PROJECTS = gql`
        query projectList {
            projects {
                _id
                id
                name
                institution
                address {
                    street
                    city
                    state
                    zip
                }
                firstDay
                participants
                creationTime
                courses {
                    start
                    end
                }
            }
        }
    `;

    const {loading, error, data, refetch} = useQuery(GET_PROJECTS);
    const [createProject, {}] = useMutation(CREATE_PROJECT);

    const [slices, setSlices] = useState(60);

    if (error) return <Redirect to="/projects"/>;
    if (loading) return <Loading/>;

    const p = data.projects.map((n: any) => {
        const project: any = {...n};
        const creationTime = new Date(parseInt(project.creationTime));
        let timeFormatted;
        if (project.firstDay) {
            const fD = new Date(parseInt(project.firstDay));
            timeFormatted = fD.toLocaleDateString('de-DE', options);
        }
        project.year = creationTime.getFullYear();
        project.state = project.address.state;
        project.city = project.address.city;
        project.street = "";
        if (project.address.street) {
            project.street = project.address.street;
        }
        if (project.address.street && project.address.zip && project.address.city) {
            project.street = project.address.street + ", " + project.address.zip + " " + project.address.city;
        }
        project.firstDay = timeFormatted || '';
        return project;
    });

    return (
        <div>
            <br/>
            <Container>
                <div className="row">
                    <div className="col-6">
                        <Button variant="outline-primary" block onClick={() => {
                            createProject({
                                variables: {
                                    institution: "",
                                    name: "Unbenanntes Projekt",
                                    address: {
                                        street: "",
                                        zip: "",
                                        city: "",
                                        state: ""
                                    },
                                    tel: "",
                                    email: "",
                                    pricePerChild: "",
                                    support: "",
                                    supportParents: "",
                                    laufzettel: "",
                                    meetingPoint: "",
                                    followUp: 2021,
                                    notes: "",
                                    zahlungsweise: "",
                                    anmeldung: "",
                                }
                            }).then((r: any) => {
                                refetch();
                                props.history.push("/projects/" + r.data?.createProject);
                            })
                        }}>
                            Neues Projekt erstellen
                        </Button>
                    </div>
                    <div className="col-6">
                        <Button variant="outline-primary" block onClick={() => {
                            setSlices(slices === 60 ? Number.MAX_SAFE_INTEGER : 60)
                        }}>
                            {slices === 60 ? "Alle Projekte darstellen" : "Weniger Projekte darstellen"}
                        </Button>

                    </div>

                </div>

            </Container>
            <br/>
            <FilterTable columns={columns} data={p} slices={slices} {...props}/>
        </div>
    );
}

export default withRouter(ProjectList);
