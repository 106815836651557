import React, {useContext, useState} from 'react';

import Button from 'react-bootstrap/Button';
import {gql, useMutation} from "@apollo/client";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import {Alert, Col, Container, Row} from "react-bootstrap";
import './Login.css';
import logo from '../../logo_neu.png';
import {LoggedIn} from "../../App";

const GENERATE_JWT = gql`
    mutation GcenerateJWT($email:String!, $password: String!) {
        generateJWT(email: $email, password: $password)
    }
`;

const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($email:String!) {
        forgotPassword(email: $email)
    }
`;


function Login(props: any) {

    const {loggedIn, setLoggedIn} = useContext(LoggedIn);
    const [forgotPassword, {}] = useMutation(FORGOT_PASSWORD);
    const [email, setEmail] = useState("");

    const e: any = null;
    const [errorMessage, setErrorMessage] = useState(e);

    const [validated, setValidated] = useState(false);

    const [password, setPassword] = useState("");

    const [invalidCredentials, setInvalidCredentials] = useState(false);

    const [generateJWT, {data}] = useMutation(GENERATE_JWT);

    const message = props.location?.state?.message;

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        generateJWT({variables: {email, password}}).then(r => {
            if (r.data.generateJWT) {
                const {data: {generateJWT: token}} = r;
                localStorage.setItem("token", token);
                setLoggedIn(true);
            } else {
                setErrorMessage(<div>
                    Fehlerhafte Email oder fehlerhaftes Passwort.<br/><br/>
                    <Button variant="danger" onClick={() => {
                        forgotPassword({
                            variables: {
                                email
                            }
                        }).then(() => setErrorMessage(<div>Ihr Passwort wurde zurückgesetzt. Folgen Sie den Anweisungen
                            in
                            der Ihnen
                            zugesendeten Email.</div>))
                    }}>Passwort zurücksetzen</Button>
                </div>);
            }
        }).catch(response => {
            localStorage.removeItem("token");
            if (response.message === "Network error: Failed to fetch") {
                setErrorMessage(<div>Keine Verbindung zum Server. Dies ist ein technisches Problem - bitte kontaktieren
                    Sie den zuständigen Administrator.</div>);
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit} validated={validated}>
            <Container>
                <Row>
                    <Col></Col>
                    <Col xs={6}>
                        <Card className='Login-card'>
                            <Card.Img variant="top" src={logo}/>
                            <Card.Body>
                                <hr/>
                                {errorMessage && <Alert variant="danger">
                                    {errorMessage}
                                </Alert>}
                                {message && <Alert variant="success">
                                    {message}
                                </Alert>}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="" isInvalid={invalidCredentials}
                                                  onChange={(e: any) => setEmail(e.target.value)} required/>

                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Passwort</Form.Label>
                                    <Form.Control type="password" placeholder="" isInvalid={invalidCredentials}
                                                  onChange={(e: any) => setPassword(e.target.value)} required/>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Anmelden
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </Form>
    );
}

export default Login;
