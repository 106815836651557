import React from 'react';

import {gql, useMutation, useQuery} from "@apollo/client";
import {Alert, Button, FormControl, InputGroup} from "react-bootstrap";

import {Redirect, withRouter} from 'react-router-dom';
import Loading from "../loading/Loading";
import {BACKEND_URL} from "../../const";
import {getJWT} from "../../App";

const DUPLICATE_PROJECT = gql`
    mutation DupicateProject($id:ID!) {
        duplicateProject(id: $id)
    }
`;

const DELETE_PROJECT = gql`
    mutation DupicateProject($id:ID!) {
        deleteProject(_id: $id)
    }
`;

const GET_PROJECT = gql`
    query project($id:ID!) {
        project(id: $id) {
            _id
            origin
        }
    }
`;


function Options(props: any) {
    const [duplicateProject, {}] = useMutation(DUPLICATE_PROJECT);
    const [deleteProject, {}] = useMutation(DELETE_PROJECT);
    const {loading, error, data, refetch} = useQuery(GET_PROJECT, {variables: {id: props.id}});

    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <Redirect to="/projects"/>
    }
    const jwt: any = getJWT();

    return (
        <>
            <Alert variant="primary">
                Sie können Ihren persönlichen Kalender per URL abonnieren. Dieser Kalender beinhaltet alle Ihre Kurse.
                <hr/>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Kalender-URL für Kurse</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        value={BACKEND_URL + "calendar/" + jwt?.payload?.id}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        readOnly
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Kalender-URL für Aufgaben</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        value={BACKEND_URL + "tasks/" + jwt?.payload?.id}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        readOnly
                    />
                </InputGroup>
            </Alert>
            <Button variant="outline-primary"
                    onClick={() => window.open(BACKEND_URL + "laufzettel/" + props.id, "_blank")} block>
                Laufzettel herunterladen
            </Button>
            <hr/>
            <Button variant="outline-primary"
                    onClick={() => {
                        duplicateProject({
                            variables: {
                                id: props.id
                            }
                        }).then((r: any) => {
                            props.history.push("/projects/" + r.data.duplicateProject)
                        })
                    }} block>
                Projekt duplizieren
            </Button>
            <hr/>
            {data.project.origin && <Button variant="outline-primary"
                                            onClick={() =>
                                                props.history.push("/projects/" + data.project.origin)
                                            } block>
                Ursprungsprojekt anzeigen
            </Button>}
            <Button variant="danger"
                    onClick={() => {
                        deleteProject({
                            variables: {
                                id: props.id
                            }
                        }).then((r: any) => {
                            props.history.go(0)
                        })
                    }} block>
                Projekt löschen
            </Button>
        </>
    );
}

export default withRouter(Options);
