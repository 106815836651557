import React, {useState} from 'react';

import Button from 'react-bootstrap/Button';
import {gql, useMutation} from "@apollo/client";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import {Alert, Col, Container, Row} from "react-bootstrap";

import '../../components/protocol/Protocol.css';
import logo from '../../logo.png';
import {Link} from "react-router-dom";

const ACTIVATE_USER = gql`
    mutation ActivateUser($password:String!, $newPassword: String!) {
        activateUser(password: $password, newPassword: $newPassword)
    }
`;

function Activate(props: any) {
    const [activateUser] = useMutation(ACTIVATE_USER);

    const [validated, setValidated] = useState(false);
    const [brokenLink, setBrokenLink] = useState(false);

    const [p1, setP1] = useState("");
    const [p2, setP2] = useState("");

    const [invalidCredentials, setInvalidCredentials] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (p1 !== p2) {
            setInvalidCredentials(true);
            return;
        }
        activateUser({
            variables: {
                password: props.match.params.id,
                newPassword: p1
            }
        }).then(() => {
            props.history.push("/login", {
                message: `Ihr Passwort wurde erfolgreich geändert. 
            Bitte melden Sie sich an.`
            });
        }).catch((error:any) => {
            error.graphQLErrors.forEach((item: any) => {
                if (item.message === "3") {
                    setBrokenLink(true);
                }
            });
        });
    };

    return (
        <Form onSubmit={handleSubmit} validated={validated}>
            <Container>
                <Row>
                    <Col></Col>
                    <Col xs={6}>
                        <Card className='Login-card'>
                            <Card.Img variant="top" src={logo}/>
                            <Card.Body>
                                <hr/>
                                <Alert variant="primary">
                                    Bitte setzen Sie unten Ihr neues Passwort für Ihren Smart-Team-Account. Anschließend
                                    können Sie sich anmelden.
                                </Alert>
                                {brokenLink && <Alert variant="danger">
                                    Der von Ihnen verwendete Link ist ungültig. Möglicherweise ist Ihr Account schon
                                    aktiviert oder Sie haben zwischenzeitlich einen neuen Passwortrücksetzungslink
                                    angefordert.<br/>
                                    Sie können diese Seite schließen oder alternativ <Link to="/login">zur
                                    Anmeldung</Link> gelangen.
                                </Alert>}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Ihr Passwort</Form.Label>
                                    <Form.Control type="password" placeholder="" isInvalid={invalidCredentials}
                                                  onChange={(e: any) => setP1(e.target.value)} required/>

                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Passwort wiederholen</Form.Label>
                                    <Form.Control type="password" placeholder="" isInvalid={invalidCredentials}
                                                  onChange={(e: any) => setP2(e.target.value)} required/>
                                    <Form.Control.Feedback type="invalid">
                                        Dieses Passwort stimmt nicht mit dem Obigen überein.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Passwort setzen
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </Form>
    );
}

export default Activate;
