import React, {useState} from 'react';

import Button from 'react-bootstrap/Button';
import {gql, useMutation} from "@apollo/client";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import {Alert, Col, Container, Row} from "react-bootstrap";
import './Stream.css';
import YouTube from "react-youtube";

const STREAM = gql`
    mutation stream($password: String!, $id: String!, $variation: String!) {
        stream(password: $password, id: $id, variation: $variation)
    }
`;


function Stream(props: any) {
    const [getStream] = useMutation(STREAM);

    const e: any = null;
    const [errorMessage, setErrorMessage] = useState(e);


    const [stream, setStream] = useState(undefined);
    const [ended, setEnded] = useState(false);

    const [password, setPassword] = useState(null);

    const [invalidCredentials, setInvalidCredentials] = useState(false);


    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        getStream({
            variables: {
                password,
                id: props.id,
                variation: props.variation
            }
        }).then((r: any) => {
            if (r.data.stream !== null) {
                setStream(r.data.stream)
            } else {
                setErrorMessage("Sie konnten nicht angemeldet werden. Überprüfen Sie, ob das von Ihnen eingegebene Passwort mit dem aus der Email übereinstimmt. Sollte der Fehler weiter bestehen, nehmen Sie bitte Kontakt mit uns auf.")
            }
        }).catch((error: any) => {
            setErrorMessage("Sie konnten nicht angemeldet werden. Sollte der Fehler weiter bestehen, nehmen Sie bitte Kontakt mit uns auf.")
        });

    };

    const onEnd = (e: any) => {
        setTimeout(() => {
            setEnded(true);
        }, 5000)
    }

    if (stream !== undefined && !ended) {
        const opts: any = {
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
            },
        };


        return (
            <div>
                <YouTube videoId={stream} className="Youtube-player" opts={opts} onEnd={onEnd}/>
                {/*<iframe ref={streamEl} style={{width: "100%", height: "calc(100vw/1.77777778)"}} src={stream}*/}
                {/*        frameBorder="0"*/}
                {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                {/*        allowFullScreen/>*/}
            </div>
        )
    }

    if (ended) {
        return (
            <>
                <Container>
                    <Row>
                        <Col></Col>
                        <Col xs={6}>
                            <Card className='Login-card'>
                                <Card.Body>
                                   <span>
                                       Herzlichen Dank für deine Teilnahme! Der Online-Kurs wurde beendet.
                                   </span>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </>
        )
    }


    return (
        <Form onSubmit={handleSubmit}>
            <Container>
                <Row>
                    <Col></Col>
                    <Col xs={6}>
                        <Card className='Login-card'>
                            <Card.Body>
                                {errorMessage && <Alert variant="danger">
                                    {errorMessage}
                                </Alert>}
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Passwort aus der Email für den Stream</Form.Label>
                                    <Form.Control type="password" placeholder="" isInvalid={invalidCredentials}
                                                  onChange={(e: any) => setPassword(e.target.value)} required/>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Anmelden
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </Form>
    );
}

export default Stream;
