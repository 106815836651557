import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Form} from "react-bootstrap";

import {gql, useMutation, useQuery} from "@apollo/client";
import Loading from "../../components/loading/Loading";
import {Redirect, withRouter} from 'react-router-dom';
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
// @ts-ignore
import {Editor} from 'react-draft-wysiwyg';

const GET_PROTOCOL = gql`
    query protocol($id:ID!) {
        protocol(id: $id) {
            _id
            creationTime
            text
            titel
            author
        }
    }
`;

const CREATE_PROTOCOL = gql`
    mutation CreateProtocol($project:ID!, $text:String!, $titel: String!, $id: ID) {
        createProtocol(project: $project, text: $text, titel: $titel, id: $id)
    }
`;

const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

const setState = (setFunc: any, value: any) => {
    setFunc(value);
};

function Report(props: any) {
    const {loading, error, data, refetch} = useQuery(GET_PROTOCOL, {variables: {id: props.location?.state?.id}});
    const [createProtocol, {}] = useMutation(CREATE_PROTOCOL);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const e: any = {}
    const [title, setTitle] = useState("");
    const [restored, setRestored] = useState(false);
    const [saved, setSaved] = useState(false);

    useEffect(() => {
        if (!loading && !error && data) {
            setTitle(data.protocol.titel);
            const report = localStorage.getItem("report");
            if (report && JSON.parse(report).id === props.location?.state?.id) {
                const decoded = convertFromRaw(JSON.parse(report).content);
                setEditorState(EditorState.createWithContent(decoded));
                setRestored(true);
            } else {
                const decoded = convertFromRaw(JSON.parse(data.protocol.text));
                setEditorState(EditorState.createWithContent(decoded));
                setRestored(false);
            }
        }
    }, [loading, data, restored])
    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <Redirect to="/projects"/>
    }

    if (!data) {
        return <Redirect to="/projects"/>
    }
    return (
        <Form onSubmit={(e: any) => {
            e.preventDefault();
            createProtocol({
                variables: {
                    id: props.location?.state?.id,
                    project: props.location?.state?.project,
                    text: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
                    titel: title
                }
            }).then(() => {
                localStorage.removeItem("report");
                setRestored(false);
                setSaved(true);
                refetch();
            });
        }}>
            <Card>
                <Card.Body>
                    <Card.Title>
                        Protokoll
                        <br/><br/>
                        <Form.Group>
                            <Form.Control size="lg" type="text" value={title}
                                          onChange={(e: any) => setInput(e, setTitle)}
                                          required/>
                            <br/>
                        </Form.Group>
                    </Card.Title>
                    {restored && <Alert variant="warning">
                        Ihre letzten Änderungen an diesem Protokoll wurden nicht von Ihnen gespeichert.
                        Daher wurde eine automatische Sicherung erstellt, welche Ihnen unten angezeigt wird.
                        Sollten Sie diese Änderung nicht wünschen, können Sie die Änderung verwerfen und den vorherigen
                        Zustand so wiederherstellen (z. B. haben Sie letztes Mal Änderung vorgenommen, die Sie
                        eigentlich verwerfen wollten).
                        <hr/>
                        <Button variant="outline-danger" onClick={() => {
                            localStorage.removeItem("report");
                            setRestored(false);
                        }
                        }>Letzten Änderungen verwerfen</Button>
                    </Alert>}
                    {saved && <Alert variant="success">
                        Ihre Änderungen wurden gespeichert.
                    </Alert>}
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(editorChange: any) => {
                            setEditorState(editorChange);
                            setSaved(false);
                            localStorage.setItem("report", JSON.stringify({
                                id: props.location?.state?.id, content: convertToRaw(editorState.getCurrentContent())
                            }));
                        }}
                    />
                    <hr/>
                    <Button variant="success" type="submit">Änderungen speichern</Button>
                </Card.Body>
            </Card>
        </Form>
    );
}

export default withRouter(Report);
