import React, {useState} from 'react';

import {gql, useMutation, useQuery} from "@apollo/client";
import {Button, Card, Container, FormControl, Modal, Table} from "react-bootstrap";


import {Redirect, withRouter} from "react-router-dom";
import {useFilters, useGlobalFilter, useTable} from 'react-table'
import Loading from "../../components/loading/Loading";
import OnlineCoursesVariantsList from "./OnlineCoursesVariantsList";
import Form from "react-bootstrap/Form";

const options = {year: 'numeric', month: '2-digit', day: '2-digit'};

const GENERATE_JWT = gql`
    mutation GcenerateJWT($email:String!, $password: String!) {
        generateJWT(email: $email, password: $password)
    }
`;

const CREATE_PRODUCT = gql`
    mutation createProduct($name: String!, $price: String!, $description: String!, $short_description: String!, $imageURL: String!) {
        createProduct(name: $name, price: $price, description: $description, short_description: $short_description, imageURL: $imageURL)
    }
`;

const UPDATE_PRODUCT = gql`
    mutation updateProduct($name: String!, $price: String!, $description: String!, $short_description: String!, $id: ID!, $imageURL: String!) {
        updateProduct(name: $name, price: $price, description: $description, short_description: $short_description, id: $id, imageURL: $imageURL)
    }
`;

const DELETE_PRODUCT = gql`
    mutation deleteProduct($id: ID!) {
        deleteProduct(id: $id)
    }
`;


// Define a default UI for filtering
function DefaultColumnFilter({
                                 column: {filterValue, preFilteredRows, setFilter},
                             }: { column: any }) {
    const count = preFilteredRows.length;

    return (
        <FormControl
            value={filterValue || ''}
            onChange={(e: any) => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            /*placeholder={`Search ${count} records...`}*/
        />
    )
}

// Our table component
function FilterTable({columns, data, history, match, id, setID}: any) {
    const filterTypes: any = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: '',
            // Or, override the default text filter to use
            // "startWith"
            text: (rows: any, id: any, filterValue: any) => {
                return rows.filter((row: any) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    );

    const defaultColumn: any = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
    }: any = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
        },
        useFilters, // useFilters!
        useGlobalFilter // useGlobalFilter!
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    const firstPageRows = rows.slice(0, 60);

    return (
        <>
            <Table className="Table-rounded" bordered hover {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup: any, index:number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup.headers.map((column: any, index2: number) => (
                            column.canFilter ? <th key={index2} {...column.getHeaderProps()}>
                                <Card.Title>
                                    {column.render('Header')}
                                </Card.Title>
                                {/* Render the columns filter UI */}
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </th> : <div key={index2}></div>
                        ))}
                    </tr>
                ))}
                <tr>
                    <th
                        colSpan={visibleColumns.length}
                        style={{
                            textAlign: 'left',
                        }}
                    >
                    </th>
                </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                {firstPageRows.map((row: any, i: any) => {
                    prepareRow(row);
                    return (
                        <tr className="tr" {...row.getRowProps()}
                            key={row.original.id}
                            style={{backgroundColor: row.original.id === id ? "rgba(0,0,0,.075)" : "inherit"}}>
                            {row.cells.map((cell: any, index: number) => {
                                if (cell.column.Header !== "Einstellungen") {
                                    return <td
                                        key={index}
                                        onClick={() => {
                                            history.push("/online-courses/" + row.original.id)
                                            setID(row.original.id)
                                        }}
                                        style={{cursor: "pointer"}}{...cell.getCellProps()}>{cell.render('Cell')}</td>
                                }
                                return <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </Table>
            <br/>
        </>
    )
}

const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

function OnlineCoursesList(props: any) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Online-Kurse',
                columns: [
                    {
                        Header: 'Kurs',
                        accessor: 'name',
                    },
                    {
                        Header: 'Einstellungen',
                        accessor: 'settings',
                    },
                ],
            }
        ],
        []
    );


    const GET_PRODUCTS = gql`
        query products {
            products {
                id
                name
                price
                description
                short_description
            }
        }
    `;

    const {loading, error, data, refetch: refetchProducts} = useQuery(GET_PRODUCTS);
    const [createProduct, {}] = useMutation(CREATE_PRODUCT);
    const [deleteProduct, {}] = useMutation(DELETE_PRODUCT);
    const [updateProduct, {}] = useMutation(UPDATE_PRODUCT);

    const [newModal, setNewModal] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalHeadline, setModalHeadline] = useState('Neuen Online-Kurs erstellen');
    const [modalPrice, setModalPrice] = useState('15');
    const [modalDescription, setModalDescription] = useState('');
    const [modalShortDescription, setModalShortDescription] = useState('');
    const [modalImageURL, setModalImageURL] = useState('https://smart-team.de/online-ticket-preview.png');

    const handleCloseTask = () => {
        if (!newModal) {
            resetModal();
        }
        setShowModal(false)
    };

    const resetModal = () => {
        setNewModal(true);
        setModalTitle('');
        setModalPrice('15');
        setModalShortDescription('');
        setModalDescription('');
        setProductID(-1);
    };

    const handleSaveTask = (e: any) => {
        e.preventDefault();
        setShowModal(false);
        let variables;
        if(productID == -1) {
            variables = {
                name: modalTitle,
                price: modalPrice,
                description: modalDescription,
                short_description: modalShortDescription,
                imageURL: modalImageURL
            };
            createProduct({
                variables
            }).then(r => {
                resetModal();
                refetchProducts();

            });
        } else {
            variables = {
                name: modalTitle,
                price: modalPrice,
                description: modalDescription,
                short_description: modalShortDescription,
                id: productID,
                imageURL: modalImageURL
            };
            updateProduct({
                variables
            }).then(r => {
                resetModal();
                refetchProducts();

            });
        }
    };


    const [id, setID] = useState(props.id);
    const [productID, setProductID] = useState(-1);

    if (error) return <Redirect to="/projects"/>;
    if (loading) return <Loading/>;

    const p = data.products.map((n: any) => {
        const project: any = {...n};
        project.settings = <>
            <Button variant={"outline-primary"} onClick={() => {
                setNewModal(false);
                setProductID(n.id)
                setModalPrice(n.price);
                setModalDescription(n.description);
                setModalShortDescription(n.short_description);
                setModalTitle(n.name);
                setModalHeadline("Online-Kurs bearbeiten");
                setShowModal(true);
            }
            }>Einstellungen</Button>
            <Button style={{marginLeft: "10px"}} variant={"outline-danger"} onClick={() => {
                deleteProduct({
                    variables: {
                        id: n.id
                    }
                }).then(r => {
                    setID(-1);
                    refetchProducts();

                });
            }
            }>Löschen</Button>
        </>
        return project;
    }).filter((n: any) => n !== null);

    return (
        <>
            <div style={{width: "50%"}}>
                <br/>
                <Container>
                    <Button variant="outline-primary" block onClick={() => {
                        setNewModal(true);
                        setShowModal(true);
                    }}>
                        Neuen Online-Kurs erstellen
                    </Button>
                </Container>
                <br/>
                <FilterTable columns={columns} data={p} {...props} id={id} setID={setID}/>
            </div>
            <OnlineCoursesVariantsList id={id}>
            </OnlineCoursesVariantsList>
            <Modal show={showModal} onHide={handleCloseTask}>
                <Form onSubmit={handleSaveTask}>
                    <Modal.Header closeButton>
                        <Modal.Title>Neuen Kurs erstellen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Titel</Form.Label>
                            <Form.Control type="text" defaultValue={modalTitle} required
                                          onInput={(e: any) => setInput(e, setModalTitle)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Preis in €</Form.Label>
                            <Form.Control type="number" step="0.01" defaultValue={modalPrice} required
                                          onInput={(e: any) => setInput(e, setModalPrice)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Beschreibung</Form.Label>
                            <Form.Control as="textarea" rows={3} defaultValue={modalDescription}
                                          onInput={(e: any) => setInput(e, setModalDescription)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Kurzbeschreibung</Form.Label>
                            <Form.Control as="textarea" rows={2} defaultValue={modalShortDescription}
                                          onInput={(e: any) => setInput(e, setModalShortDescription)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Bild</Form.Label>
                            <Form.Control type="url" defaultValue={modalImageURL}
                                          onInput={(e: any) => setInput(e, setModalImageURL)}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseTask}>
                            Schließen
                        </Button>
                        <Button variant="primary" type="submit">
                            Speichern
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    );
}

export default withRouter(OnlineCoursesList);
