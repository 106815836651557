import React, {useRef, useState} from 'react';
import './App.css';
import {InMemoryCache} from 'apollo-cache-inmemory';
import Login from "./screens/login/Login";

import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import {decode} from "jsonwebtoken";
import Dashboard from "./screens/dashboard/Dashboard";
import Project from "./screens/project/Project";
import ProjectList from "./screens/project-list/ProjectList";
import Settings from "./screens/settings/Settings";
import TaskOverview from "./screens/task-overview/TaskOverview";
import Activate from "./screens/activate/Activate";
import Report from "./screens/report/Report";
import {BACKEND_URL} from "./const";
import OnlineCoursesList from "./screens/online-courses-list/OnlineCoursesList";
import {ApolloClient, ApolloProvider, createHttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import OnlineCourse from "./screens/online-course/OnlineCourse";
import Stream from "./screens/stream/Stream";

const jwtExpired = (jwt: any): boolean => {
    const decoded: any = decode(jwt, {complete: true});
    const dateNow = Math.floor(Date.now() / 1000);
    if (!decoded || decoded.payload.exp < dateNow) {
        return true;
    }
    return false;

};

const defaultJWTStatus = () => {
    const jwt = localStorage.getItem("token");
    if (jwt !== null) {
        return !jwtExpired(jwt);
    }
    return false;
};

const getJWT = () => {
    const jwt = localStorage.getItem("token");
    if (jwt !== null) {
        return decode(jwt, {complete: true});
    }
};

const LoggedIn = React.createContext({
    loggedIn: true, setLoggedIn: (v: boolean) => {
    }
});

function App(props: any) {
    const [loggedIn, setLoggedIn] = useState(defaultJWTStatus);
    const router: any = useRef(null);
    if (!loggedIn) {
        localStorage.removeItem("token");
    }

    const httpLink = createHttpLink({
        uri: BACKEND_URL + 'graphql'
    });

    const authLink = setContext((_, {headers}) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem('token');
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });


    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        // @ts-ignore
        cache: new InMemoryCache()

    });

    return (
        <LoggedIn.Provider value={{loggedIn, setLoggedIn}}>
            <ApolloProvider client={client}>
                <Router ref={router}>
                    <Switch>
                        <Route path="/login" component={(props: any) => {
                            if (loggedIn) {
                                return <Redirect to="/dashboard"/>
                            }
                            return <Login {...props}/>
                        }
                        }/>
                        <Route path="/error" component={(props: any) => {
                            return <Dashboard>
                                <div>{props.location?.state?.error}</div>
                            </Dashboard>
                        }}/>
                        <Route path="/activate/:id" component={(props: any) => {
                            return <Activate {...props}/>
                        }
                        }/>
                        <Route exact path="/stream/:id/:variation" component={(props: any) => {
                            return <Stream id={props.match.params.id} variation={props.match.params.variation}/>
                        }
                        }/>
                        {!loggedIn &&
                        <Redirect to='/login'/>}
                        <Route path="/settings" component={(props: any) => {
                            return <Dashboard>
                                <Settings/>
                            </Dashboard>
                        }}/>
                        <Route path="/dashboard">
                            <Redirect to="/tasks"/>
                        </Route>
                        <Route path="/tasks" component={(props: any) => {
                            return <Dashboard>
                                <TaskOverview/>

                            </Dashboard>
                        }}/>
                        <Route path="/report/:id" component={(props: any) => {
                            return <Dashboard>
                                <Report/>

                            </Dashboard>
                        }}/>
                        <Route exact path="/projects" component={(props: any) => {
                            return <Dashboard>
                                <ProjectList/>
                            </Dashboard>
                        }}/>
                        <Route exact path="/online-courses" component={(props: any) => {
                            return <Dashboard>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <OnlineCoursesList id={-1}/>
                                </div>
                            </Dashboard>
                        }}/>
                        <Route path="/projects/:id/:tab?" component={(props: any) => {
                            // @ts-ignore
                            return <Dashboard><Project id={props.match.params.id}/>
                            </Dashboard>
                        }}/>
                        <Route exact path="/online-courses/:id/" component={(props: any) => {
                            return <Dashboard>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <OnlineCoursesList id={props.match.params.id}/>
                                </div>
                            </Dashboard>
                        }}/>
                        <Route exact path="/online-courses/:id/:variation" component={(props: any) => {
                            return <Dashboard>
                                <OnlineCourse id={props.match.params.id} variation={props.match.params.variation}/>
                            </Dashboard>
                        }}/>
                        <Route path="/">
                            <Redirect to="/dashboard"/>
                        </Route>
                    </Switch>
                </Router>
            </ApolloProvider>
        </LoggedIn.Provider>
    );
}

export {LoggedIn, getJWT};
export default App;
