import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Container, Form, Table} from "react-bootstrap";

import {gql, useMutation, useQuery} from "@apollo/client";
import Loading from "../../components/loading/Loading";
import {Redirect} from "react-router-dom";

const GET_ORDERS = gql`
    query orders($id:ID!,$variation:String!) {
        orders(id: $id, variation: $variation) {
            id
            status
            first_name
            last_name
            email
        }
    }
`;

const GET_YOUTUBE_STREAM_URL = gql`
    query youtubeStreamURL($id: String!, $variation: String!) {
        youtubeStreamURL(id: $id, variation: $variation)
    }

`;

const UPDATE_YOUTUBE_STREAM_URL = gql`
    mutation updateYoutubeStreamURL($url:String!, $id: String!, $variation: String!) {
        updateYoutubeStreamURL(url: $url, id: $id, variation: $variation)
    }
`;

const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

const setState = (setFunc: any, value: any) => {
    setFunc(value);
};

function OnlineCourse(props: any) {
    const {loading, error, data} = useQuery(GET_ORDERS, {variables: {id: props.id, variation: props.variation}});
    const [updateYoutubeStreamURL, {}] = useMutation(UPDATE_YOUTUBE_STREAM_URL);
    const {loading: loadingURL, error: errorURL, data: dataURL, refetch: refetchURL} = useQuery(GET_YOUTUBE_STREAM_URL, {
        variables: {
            id: props.id,
            variation: props.variation
        }
    });

    const [youtubeURL, setYouTubeURl] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        if (!loadingURL && !errorURL && dataURL) {
            setYouTubeURl(dataURL.youtubeStreamURL);
        }

    }, [loadingURL, dataURL]);


    const submitYouTubeStreamURL = (e: any) => {
        e.preventDefault();
        updateYoutubeStreamURL({
            variables: {
                url: youtubeURL,
                id: props.id,
                variation: props.variation
            }
        }).then(r => {
            refetchURL();
            setShowSuccess(true);
        }).catch(r => console.log(r));
    }

    if (loading || loadingURL) {
        return <Loading/>
    }
    if (error || errorURL) {
        return <Redirect to="/online-courses"/>
    }

    if (!data.orders) {
        return <Redirect to="/online-courses"/>
    }
    return (
        <Container>
            <Card style={{marginTop: 40}}>
                <Card.Body>
                    <Form onSubmit={submitYouTubeStreamURL}>
                        <Card.Title>Online-Kurse</Card.Title>
                        <Form.Group>
                            {showSuccess && <Alert variant="success">
                                Ihre Änderung der URL wurde gespeichert.
                            </Alert>}
                            <Form.Label>URL des YouTube-Streams</Form.Label>
                            <Form.Control type="url" required value={youtubeURL}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setYouTubeURl(e.target.value)}/>
                        </Form.Group>
                        <hr/>
                        <Button variant="success" type="submit">Speichern</Button>
                    </Form>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <Card.Title>Teilnehmer vom Kurs am {props.variation}</Card.Title>
                    <hr/>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Nachname</th>
                            <th>Vorname</th>
                            <th>Email</th>
                            <th>ID</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.orders.map((e: any, index: number) => <tr>
                            <td>{++index}</td>
                            <td>{e.last_name}</td>
                            <td>{e.first_name}</td>
                            <td>{e.email}</td>
                            <td>{e.id}</td>
                            <td>{e.status === "pending" ?
                                <Button variant="warning">Ausstehend</Button> : e.status === "completed" ?
                                    <Button variant="success">Vollendet</Button> :
                                    <Button variant="warning">{e.status}</Button>}</td>
                        </tr>)}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default OnlineCourse;
