import React, {useState} from 'react';

import {gql, useMutation, useQuery} from "@apollo/client";
import {Button, Card, Container, FormControl, Modal, Table} from "react-bootstrap";


import {Link, Redirect, withRouter} from "react-router-dom";
import {useFilters, useGlobalFilter, useTable} from 'react-table'
import Loading from "../../components/loading/Loading";
import Form from "react-bootstrap/Form";

const options = {year: 'numeric', month: '2-digit', day: '2-digit'};

const GENERATE_JWT = gql`
    mutation GcenerateJWT($email:String!, $password: String!) {
        generateJWT(email: $email, password: $password)
    }
`;

const CREATE_PRODUCT_VARIATION = gql`
    mutation createProductVariation($title: String!, $id: ID!) {
        createProductVariation(title: $title, id: $id)
    }
`;

const DELETE_PRODUCT_VARIATION = gql`
    mutation deleteProductVariation($id: ID!, $title: String!) {
        deleteProductVariation(id: $id, title: $title)
    }
`;


const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

// Define a default UI for filtering
function DefaultColumnFilter({
                                 column: {filterValue, preFilteredRows, setFilter},
                             }: { column: any }) {
    const count = preFilteredRows.length;

    return (
        <FormControl
            value={filterValue || ''}
            onChange={(e: any) => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            /*placeholder={`Search ${count} records...`}*/
        />
    )
}

// Our table component
function FilterTable({columns, data, history, match, id}: any) {
    const filterTypes: any = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: '',
            // Or, override the default text filter to use
            // "startWith"
            text: (rows: any, id: any, filterValue: any) => {
                return rows.filter((row: any) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    );

    const defaultColumn: any = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
    }: any = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
        },
        useFilters, // useFilters!
        useGlobalFilter // useGlobalFilter!
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    const firstPageRows = rows.slice(0, 60);

    return (
        <>
            <Table className="Table-rounded" bordered hover {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup: any) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            column.canFilter ? <th {...column.getHeaderProps()}>
                                <Card.Title>
                                    {column.render('Header')}
                                </Card.Title>
                                {/* Render the columns filter UI */}
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </th> : <div></div>
                        ))}
                    </tr>
                ))}
                <tr>
                    <th
                        colSpan={visibleColumns.length}
                        style={{
                            textAlign: 'left',
                        }}
                    >
                    </th>
                </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                {firstPageRows.map((row: any, i: any) => {
                    prepareRow(row);
                    return (
                        <tr className="tr" {...row.getRowProps()}
                        >
                            {row.cells.map((cell: any) => {
                                if (cell.column.Header !== "Einstellungen") {
                                    return <td style={{
                                        padding: "0",
                                    }} {...cell.getCellProps()}><Link
                                        to={match.url + "/" + row.original.title}
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                        }}
                                    >
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            padding: ".75rem",
                                            cursor: "pointer",
                                        }}>{cell.render('Cell')}</div>
                                    </Link></td>
                                }
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </Table>
            <br/>
        </>
    )
}

function OnlineCoursesVariantsList(props: any) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Online-Kurse',
                columns: [
                    {
                        Header: 'Datum',
                        accessor: 'title',
                    },
                    {
                        Header: 'Einstellungen',
                        accessor: 'settings',
                    },
                ],
            }
        ],
        []
    );


    const GET_PROJECTS = gql`

        query projectList($id: ID!) {
            productVariants(id:$id) {
                id
                options
            }
        }
    `;

    const [newModal, setNewModal] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');

    const handleCloseTask = () => {
        if (!newModal) {
            resetModal();
        }
        setShowModal(false)
    };

    const resetModal = () => {
        setNewModal(true);
        setModalTitle('');
    };

    const handleSaveTask = (e: any) => {
        e.preventDefault();
        setShowModal(false);
        const variables: any = {
            title: modalTitle,
            id: props.id
        };

        createProductVariation({
            variables
        }).then((r: any) => {
            resetModal();
            refetch()

        });
    };

    const {loading, error, data, refetch} = useQuery(GET_PROJECTS, {
        variables: {id: props.id},
        skip: props.id === -1,
        fetchPolicy: "network-only"
    });
    const [createProductVariation, {}] = useMutation(CREATE_PRODUCT_VARIATION);
    const [deleteProductVariation, {}] = useMutation(DELETE_PRODUCT_VARIATION);

    if (props.id === -1) {
        return (
            <>
            </>
        )
    }
    if (error) return <Redirect to="/projects"/>;
    if (loading) return <Loading/>;
    if (!data.productVariants[0]) {
        return (
            <>
            </>
        )
    }
    const p = data.productVariants[0].options.map((n: any) => {
        if (n === "") {
            return null;
        }
        const project: any = {};
        project.settings = <Button variant={"outline-danger"} onClick={(e: any) => {
            e.preventDefault();
            deleteProductVariation({
                variables: {
                    id: props.id,
                    title: n
                }
            }).then(r => {
                refetch();

            });
        }
        }>Löschen</Button>
        project.title = n
        return project;

    }).filter((n: any) => n !== null)
    return (
        <div style={{width: "50%"}}>
            <br/>
            <Container>
                <Button variant="outline-primary" block onClick={() => {
                    setShowModal(true);
                }}>
                    Datum hinzufügen
                </Button>
            </Container>
            <br/>
            <FilterTable columns={columns} data={p} {...props}/>
            <Modal show={showModal} onHide={handleCloseTask}>
                <Form onSubmit={handleSaveTask}>
                    <Modal.Header closeButton>
                        <Modal.Title>Datum hinzufügen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Datum</Form.Label>
                            <Form.Control type="text" defaultValue={modalTitle} required
                                          onInput={(e: any) => setInput(e, setModalTitle)}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseTask}>
                            Schließen
                        </Button>
                        <Button variant="primary" type="submit">
                            Speichern
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}

export default withRouter(OnlineCoursesVariantsList);
