import React, {useContext, useEffect, useState} from 'react';

import {gql, useMutation, useQuery} from "@apollo/client";
import {
    Alert,
    Button,
    Card,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    FormControl,
    InputGroup,
    Table
} from "react-bootstrap";


import {getJWT, LoggedIn} from "../../App";
import Loading from "../../components/loading/Loading";
import { Redirect, withRouter } from 'react-router-dom';

const GET_USERS = gql`
    {
        users {
            firstname
            lastname
            email
        }
    }
`;

const GET_TERMINSCHEMAS = gql`
    {
        terminschemas {
            name
            institution
            zahlungsweise
            anmeldung
            _id
        }
    }
`;

const UPDATE_USER = gql`
    mutation UpdateUser($firstname:String!, $lastname:String!,$email:String!,$password:String!,$oldPassword:String!) {
        updateUser(firstname: $firstname, lastname: $lastname, email: $email, oldPassword: $oldPassword, password: $password)
        {
            email
        }
    }
`;

const CREATE_USER = gql`
    mutation CreateUser($firstname:String!, $lastname:String!,$email:String!) {
        createUser(firstname: $firstname, lastname: $lastname, email: $email)
        {
            email
        }
    }
`;

const CREATE_TERMINSCHEMA = gql`
    mutation CreateTerminschema($name:String!, $institution:String!) {
        createTerminschema(name: $name, institution: $institution)
    }
`;

const DELETE_USER = gql`
    mutation DeleteUser($email:String!) {
        deleteUser(email: $email)
    }
`;

const DELETE_TERMINSCHEMA = gql`
    mutation DeleteTerminschema($id:ID!) {
        deleteTerminschema(_id: $id)
    }
`;

const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

const setState = (setFunc: any, value: any) => {
    setFunc(value);
};

function Settings(props: any) {
    const {setLoggedIn} = useContext(LoggedIn);
    const jwt: any = getJWT();

    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidEmailNewUser, setInvalidEmailNewUser] = useState(false);
    const [invalidOldPassword, setInvalidOldPassword] = useState(false);

    const [passwordRequired, setPasswordRequired] = useState(false);

    const [firstname, setFirstname] = useState(jwt.payload.firstname);
    const [firstnameNewUser, setFirstnameNewUser] = useState(jwt.payload.firstname);
    const [lastname, setLastname] = useState(jwt.payload.lastname);
    const [lastnameNewUser, setLastnameNewUser] = useState(jwt.payload.lastname);
    const [email, setEmail] = useState(jwt.payload.email);
    const [emailNewUser, setEmailNewUser] = useState(jwt.payload.email);
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");

    const [terminschemaName, setTerminschemaName] = useState('');
    const [institution, setInstitution] = useState('Kita');
    const [zahlungsweise, setZahlungsweise] = useState('bar');
    const [anmeldung, setAnmeldung] = useState('Online');

    const [updateUser, {}] = useMutation(UPDATE_USER);
    const [createUser, {}] = useMutation(CREATE_USER);
    const [deleteUser, {}] = useMutation(DELETE_USER);
    const [deleteTerminschema, {}] = useMutation(DELETE_TERMINSCHEMA);
    const [createTerminschema, {}] = useMutation(CREATE_TERMINSCHEMA);

    const {loading, error, data, refetch} = useQuery(GET_USERS);
    const {loading: loadingTerminschema, error: errorTerminschema, data: dataTerminschema, refetch: refetchTerminschema} = useQuery(GET_TERMINSCHEMAS);

    const submitPersonalSettings = (e: any) => {
        e.preventDefault();
        if (oldPassword !== "" || password !== "") {

        }
        updateUser({variables: {firstname, lastname, email, password, oldPassword}}).then(r => {
            setLoggedIn(false);
            props.history.push("/login", {
                message: `Ihre Daten wurden erfolgreich geändert. 
            Bitte melden Sie sich erneuert an.`
            });
        }).catch(error => {
            error.graphQLErrors.forEach((item: any) => {
                if (item.message === "1") {
                    setInvalidEmail(true);
                } else if (item.message === "2") {
                    setInvalidOldPassword(true);
                }
            });
        });
    };

    const submitNewUser = (e: any) => {
        e.preventDefault();
        createUser({
            variables: {
                firstname: firstnameNewUser,
                lastname: lastnameNewUser,
                email: emailNewUser
            }
        }).then(r => refetch()).catch(error => {
            error.graphQLErrors.forEach((item: any) => {
                if (item.message === "1") {
                    setInvalidEmailNewUser(true);
                }
            });
        })
    };

    if (loading || loadingTerminschema) {
        return <Loading/>
    }
    if (error || errorTerminschema) {
        return <Redirect to="/projects"/>
    }

    if (!data.users || !dataTerminschema.terminschemas) {
        return <Redirect to="/dashboard"/>
    }

    return (
        <Container>
            <Card style={{marginTop: 40}}>
                <Card.Body>
                    <Card.Title>Persönliche Einstellungen</Card.Title>
                    <Alert variant="primary">
                        Im Folgendem können Sie Einstellungen vornehmen, die nur Sie betreffen.
                        Die hier angezeigten Daten wie der Name werden unteranderem bei Personenzuweisungen in Projekten
                        verwendet.
                    </Alert>
                    <Form onSubmit={submitPersonalSettings}>
                        <Form.Group>
                            <Form.Label>Vorname</Form.Label>
                            <Form.Control type="text" defaultValue={jwt.payload.firstname} readOnly
                                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => setFirstname(e.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Nachname</Form.Label>
                            <Form.Control type="text" defaultValue={jwt.payload.lastname} readOnly
                                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => setLastname(e.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" required defaultValue={jwt.payload.email}
                                          isInvalid={invalidEmail}
                                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              setEmail(e.target.value);
                                              setInvalidEmail(false);
                                          }}/>
                            <Form.Control.Feedback type="invalid">
                                Die Email wird bereits verwendet.
                            </Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                Die Email wird zum Anmelden und Zurücksetzen des Passworts verwendet.
                            </Form.Text>

                        </Form.Group>
                        <hr/>
                        <Card.Title>Passwort ändern</Card.Title>
                        <Form.Group>
                            <Form.Label>Neues Passwort</Form.Label>
                            <Form.Control type="password" defaultValue="" required={passwordRequired}
                                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Aktuelles Passwort</Form.Label>
                            <Form.Control type="password" isInvalid={invalidOldPassword}
                                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              setOldPassword(e.target.value);
                                              setPasswordRequired(e.target.value !== "");
                                          }}/>
                            <Form.Control.Feedback type="invalid">
                                Das Passwort entspricht nicht dem aktuellen Passwort.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <hr/>
                        <Button variant="success" type="submit">Speichern</Button>
                    </Form>
                </Card.Body>
            </Card>

            <Card style={{marginTop: 40}}>
                <Card.Body>
                    <Card.Title>Nutzerverwaltung</Card.Title>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Nachname</th>
                            <th>Vorname</th>
                            <th>Email</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {data.users.map((user: any) => {
                            return (
                                <tr key={user.email}>
                                    <td>{user.lastname}</td>
                                    <td>{user.firstname}</td>
                                    <td>{user.email}</td>
                                    <td><Button variant="outline-danger"
                                                onClick={() => deleteUser({variables: {email: user.email}}).finally(() => refetch())}>Löschen</Button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    <hr/>
                    <Card.Title>Neuen Nutzer erstellen</Card.Title>
                    <Form onSubmit={submitNewUser}>
                        <Form.Group>
                            <Form.Label>Vorname</Form.Label>
                            <Form.Control type="text"
                                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => setFirstnameNewUser(e.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Nachname</Form.Label>
                            <Form.Control type="text"
                                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => setLastnameNewUser(e.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" required onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setEmailNewUser(e.target.value);
                                setInvalidEmailNewUser(false);
                            }} isInvalid={invalidEmailNewUser}/>
                            <Form.Control.Feedback type="invalid">
                                Die Email wird bereits verwendet.
                            </Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                Der Nutzer erhält eine initiale Email zum Festlegen seines Passworts.
                            </Form.Text>
                        </Form.Group>
                        <hr/>
                        <Button variant="success" type="submit">Erstellen</Button>
                    </Form>
                </Card.Body>
            </Card>
            <br/>
        </Container>
    );
}

export default withRouter(Settings);
