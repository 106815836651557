import React, {useState} from 'react';

import {Card, Tab, Tabs} from "react-bootstrap";

import '../project/Project.css';

import {Redirect, withRouter} from 'react-router-dom';
import Loading from "../../components/loading/Loading";
import BaseData from "../../components/base-data/BaseData";
import Protocol from "../../components/protocol/Protocol";
import Tasks from "../../components/tasks/Tasks";
import Participants from "../../components/participants/Participants";
import Contacts from "../../components/contacts/Contacts";
import Courses from "../../components/courses/Courses";
import Options from "../../components/options/Options";
import {gql, useQuery} from "@apollo/client";
import Coupons from "../../components/coupons/Coupons";

const GET_PROJECT = gql`
    query project($id:ID!) {
        project(id: $id) {
            _id
            name
            institution
            address {
                street
                city
                state
            }
            participants
            creationTime
            courses {
                start
                end
            }
        }
    }
`;

const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

const setState = (setFunc: any, value: any) => {
    setFunc(value);
};

function Project(props: any) {
    const {loading, error, data} = useQuery(GET_PROJECT, {variables: {id: props.id}});

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <Redirect to="/projects"/>
    }

    if (!data.project) {
        return <Redirect to="/projects"/>
    }
    return (
        <Card>
            <Card.Body>
                <Card.Title>Projekt {data.project.name}</Card.Title>
                <hr/>
                <Tabs defaultActiveKey={props.match.params.tab ? props.match.params.tab : "stammdaten"}
                      id="uncontrolled-tab-example"
                      onClick={(e: any) => {
                          if (e.target.getAttribute("data-rb-event-key") !== null) {
                              props.history.push("/projects/" + props.id + "/" + e.target.getAttribute("data-rb-event-key"))
                          }
                      }}>
                    <Tab eventKey="stammdaten" title="Stammdaten">
                        <br/>
                        <BaseData {...{id: props.id}}/>
                    </Tab>
                    <Tab eventKey="protokolle" title="Protokolle">
                        <br/>
                        <Protocol {...{id: props.id}}/>

                    </Tab>
                    <Tab eventKey="courses" title="Kurse">
                        <br/>
                        <Courses {...{id: props.id, institution: data.project.institution}}/>
                    </Tab>
                    <Tab eventKey="coupons" title="Coupons">
                        <br/>
                        <Coupons {...{id: props.id}}/>
                    </Tab>
                    <Tab eventKey="tasks" title="Aufgaben">
                        <br/>
                        <Tasks {...{id: props.id}}/>
                    </Tab>
                    <Tab eventKey="participants" title="Teilnehmer">
                        <br/>
                        <Participants {...{id: props.id}}/>
                    </Tab>
                    <Tab eventKey="contacts" title="Kontakte">
                        <br/>
                        <Contacts {...{id: props.id}}/>
                    </Tab>
                    <Tab eventKey="options" title="Optionen">
                        <br/>
                        <Options {...{id: props.id}}/>
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    );
}

export default withRouter(Project);
