import React, {useState} from 'react';

import {gql, useMutation, useQuery} from "@apollo/client";
import Form from "react-bootstrap/Form";
import {Button, ButtonGroup, Card, Modal, ToggleButton} from "react-bootstrap";

import {Redirect} from 'react-router-dom';
import Loading from "../../components/loading/Loading";
import {withApollo} from "@apollo/client/react/hoc";
import {getJWT} from "../../App";

const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

const GET_TASKS = gql`
    query Tasks($project:ID!, $my:Boolean!, $done:Boolean!) {
        tasks(project: $project, my: $my, done: $done) {
            processor
            text
            titel
            date
            _id
        }
    }
`;

const CREATE_TASK = gql`
    mutation CreateTask($project:ID!, $text:String!, $titel: String!, $date: String!, $processor: String!, $id: ID, $done: Boolean) {
        createTask(project: $project, text: $text, titel: $titel, date: $date, processor: $processor, id: $id, done: $done)
    }
`;

const GET_USERS = gql`
    {
        users {
            firstname
            lastname
            email
        }
    }
`;

const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

const setState = (setFunc: any, value: any) => {
    setFunc(value);
};

function Tasks(props: any) {
    const [taskQuery, setTaskQuery] = useState({variables: {project: props.id, my: true, done: false}});
    const {loading, error, data, refetch} = useQuery(GET_TASKS, taskQuery);
    const {loading: loadingUsers, error: errorUsers, data: dataUsers} = useQuery(GET_USERS);

    const [createTask, {}] = useMutation(CREATE_TASK);

    const [showTask, setShowTask] = useState(false);
    const resetModal = () => {
        setNewModal(true);
        setNewTaskTitel('');
        setNewTask('');
        setNewProcessor(jwt.payload.firstname + " " + jwt.payload.lastname);
        setNewDueTime('');
        setNewId('');
    };
    const handleCloseTask = () => {
        if (!newModal) {
            resetModal();
        }
        setShowTask(false)
    };
    const handleShowTask = () => setShowTask(true);
    const handleSaveTask = (e: any) => {
        e.preventDefault();
        setShowTask(false);
        const variables: any = {
            project: props.id,
            text: newTask,
            titel: newTaskTitel,
            processor: newProcessor,
            date: Date.parse(newDueTime).toString(),
            done: false
        };

        if (newId !== '') {
            variables.id = newId;
        }
        createTask({
            variables
        }).then(r => {
            resetModal();
            refetch();
        });
    };

    const markTaskDone = (variables: any) => {
        createTask({
            variables
        }).then(r => {
            refetch();
        });
    };

    const primary: any = 'primary';
    const outlinePrimary: any = 'outline-primary';
    const [allTasks, setAllTasks] = useState(outlinePrimary);
    const [deletedTask, setDeletedTasks] = useState(outlinePrimary);
    const [userTasks, setUserTasks] = useState(primary);
    const [newTask, setNewTask] = useState('');
    const [newTaskTitel, setNewTaskTitel] = useState('');
    const [newDueTime, setNewDueTime] = useState('');

    const jwt: any = getJWT();

    const [newProcessor, setNewProcessor] = useState(jwt.payload.firstname + " " + jwt.payload.lastname);
    const [newModal, setNewModal] = useState(true);
    const [newId, setNewId] = useState('');

    if (loading || loadingUsers) {
        return <Loading/>
    }
    if (error || errorUsers) {
        return <Redirect to="/projects"/>
    }

    return (
        <>
            <Button variant="outline-primary" onClick={handleShowTask} block>Neue Aufgabe erstellen</Button>
            <br/>
            <ButtonGroup toggle>
                <ToggleButton variant={userTasks} type="radio" name="radio" defaultChecked value="1" onClick={() => {
                    setAllTasks(outlinePrimary);
                    setDeletedTasks(outlinePrimary);
                    setUserTasks(primary);
                    setTaskQuery({variables: {project: props.id, my: true, done: false}});
                    refetch();
                }}>
                    Meine Aufgaben
                </ToggleButton>
                <ToggleButton variant={allTasks} type="radio" name="radio" value="2" onClick={() => {
                    setAllTasks(primary);
                    setDeletedTasks(outlinePrimary);
                    setUserTasks(outlinePrimary);
                    setTaskQuery({variables: {project: props.id, my: false, done: false}});
                    refetch();
                }}>
                    Alle Aufgaben
                </ToggleButton>
                <ToggleButton variant={deletedTask} type="radio" name="radio" value="3" onClick={() => {
                    setAllTasks(outlinePrimary);
                    setUserTasks(outlinePrimary);
                    setDeletedTasks(primary);
                    setTaskQuery({variables: {project: props.id, my: false, done: true}});
                    refetch();
                }}>
                    Erledigte Aufgaben
                </ToggleButton>
            </ButtonGroup>
            <br/><br/>
            {data.tasks.map((task: any) => {
                const dueTime = new Date(parseInt(task.date));
                const now = new Date();
                const timeFormatted = dueTime.toLocaleDateString('de-DE', options);
                let due = '';
                if (now > dueTime) {
                    due = '1px solid #d9534f'
                }
                return (
                    <div key={task._id}>
                        <Card style={{border: due}}>
                            <Card.Body>
                                <Card.Title>{task.titel}</Card.Title>
                                <Card.Subtitle
                                    className="mb-2 text-muted">{timeFormatted} - {task.processor}</Card.Subtitle>
                                <Card.Text style={{whiteSpace: 'pre-wrap'}}>
                                    {task.text}
                                </Card.Text>
                                {deletedTask !== primary && <Card.Link onClick={(e: any) => {
                                    markTaskDone({
                                        project: props.id,
                                        text: task.text,
                                        titel: task.titel,
                                        processor: task.processor,
                                        date: task.date,
                                        id: task._id,
                                        done: true
                                    });
                                }} href="#">Erledigt</Card.Link>}
                                {deletedTask !== primary && <Card.Link onClick={() => {
                                    setNewModal(false);
                                    setNewTaskTitel(task.titel);
                                    setNewTask(task.text);
                                    setNewProcessor(task.processor);
                                    setNewDueTime(new Date(parseInt(task.date)).toISOString().slice(0, 10));
                                    setNewId(task._id);
                                    setShowTask(true);
                                }} href="#">Ändern</Card.Link>}
                            </Card.Body>
                        </Card>
                        <br/>
                    </div>)
            })}


            <Modal show={showTask} onHide={handleCloseTask}>
                <Form onSubmit={handleSaveTask}>
                    <Modal.Header closeButton>
                        <Modal.Title>{newModal ? "Neue Aufgabe erstellen" : "Aufgabe bearbeiten"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Titel</Form.Label>
                            <Form.Control type="text" defaultValue={newTaskTitel} required
                                          onInput={(e: any) => setInput(e, setNewTaskTitel)}/>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" rows={10} defaultValue={newTask}
                                          onInput={(e: any) => setInput(e, setNewTask)}/>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect2">
                            <Form.Label>Bearbeiter</Form.Label>
                            <Form.Control as="select" defaultValue={newProcessor} required
                                          onChange={(e: any) => setInput(e, setNewProcessor)}>
                                {dataUsers.users.map((user: any) =>
                                    <option
                                        key={user.email}
                                        value={user.firstname + " " + user.lastname}>{user.firstname + " " + user.lastname}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Fällig am</Form.Label>
                            <Form.Control type="date" required defaultValue={newDueTime}
                                          onInput={(e: any) => setInput(e, setNewDueTime)}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseTask}>
                            Schließen
                        </Button>
                        <Button variant="primary" type="submit">
                            Speichern
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    );
}

export default withApollo(Tasks);
