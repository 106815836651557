import React from 'react';
import {Alert, Button, Card, Container, InputGroup} from "react-bootstrap";


import {Redirect, withRouter} from "react-router-dom";


import Loading from "../../components/loading/Loading";
import {getJWT} from "../../App";
import Form from "react-bootstrap/Form";
import {gql, useQuery} from "@apollo/client";

const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

const GET_TASKS = gql`
    query TasksByUser($user: String!){
        tasksByUser(user: $user) {
            processor
            text
            titel
            date
            _id
            project
            projectName
        }
    }
`;

const GET_COURSES = gql`
    query CoursesByUser($user: String!){
        coursesByUser(user: $user) {
            _id
            date
            start
            end
            category
            processor
            subject
            note
            project
            projectName
        }
    }
`;

const compare = (a: any, b: any) => {
    if (a.date < b.date) {
        return -1;
    }
    if (a.date > b.date) {
        return 1;
    }
    return 0;
}

function TaskOverview(props: any) {
    const jwt: any = getJWT();
    const {loading: loadingCourses, error: errorCourses, data: dataCourses} = useQuery(GET_COURSES, {variables: {user: jwt.payload.firstname + " " + jwt.payload.lastname}});
    const {loading: loadingTasks, error: errorTasks, data: dataTasks} = useQuery(GET_TASKS, {variables: {user: jwt.payload.firstname + " " + jwt.payload.lastname}});

    if (loadingCourses || loadingTasks) {
        return <Loading/>
    }
    if (errorCourses || errorTasks) {
        return <Redirect to="/projects"/>
    }

    const all: any = dataCourses.coursesByUser.concat(dataTasks.tasksByUser);
    all.sort(compare);
    const allByDate: any = [];
    all.forEach((i: any) => {
        if (!allByDate[i.date]) {
            allByDate[i.date] = [];
        }
        allByDate[i.date].push(i)
    });
    let oldDate: any;
    return (
        <Container>
            <br/>
            <Alert variant="primary">
                Folgende Kurse und Aufgaben haben Sie innerhalb der nächsten 2 Wochen.
            </Alert>
            {Object.entries(allByDate).map((g: any, j: number) => {
                const date = new Date(parseInt(g[0]));
                const timeFormatted = date.toLocaleDateString('de-DE', options);
                const allItems: any = {};
                g[1].forEach((i: any) => {
                    if (!allItems[i.project]) {
                        allItems[i.project] = [];
                    }
                    allItems[i.project].push(i)
                });
                return (
                    <div key={j}>
                        <Card style={{backgroundColor: "#f7f7f7"}}>
                            <Card.Body>
                                <Card.Title>
                                    {timeFormatted}
                                </Card.Title>
                                <hr/>
                                {Object.entries(allItems).map((p: any, k: number) => {
                                    return (
                                        <div key={k}>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>
                                                        {p[1][0]?.projectName}
                                                    </Card.Title>
                                                    {
                                                        Object.values(p[1]).map((i: any) => {
                                                            if (i.__typename === "Course") {
                                                                return (
                                                                    <div key={i._id}>
                                                                        <Form.Group>
                                                                            <Form.Label>{i.category}</Form.Label>
                                                                            <InputGroup>
                                                                                <Form.Control type="text"
                                                                                              value={i.start + " - " + i.end}
                                                                                />
                                                                            </InputGroup>
                                                                        </Form.Group>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div key={i._id}>
                                                                        <hr/>
                                                                        <Alert variant="primary">
                                                                            <Alert.Heading>{i.titel}</Alert.Heading>
                                                                            <p style={{whiteSpace: 'pre-wrap'}}>
                                                                                {i.text}
                                                                            </p>
                                                                        </Alert>
                                                                        <hr/>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    <Button variant="primary"
                                                            onClick={() => props.history.push("/projects/" + p[0])}>Zum
                                                        Projekt</Button>
                                                </Card.Body>
                                            </Card>
                                            <br/>
                                        </div>
                                    )
                                })}
                            </Card.Body>
                        </Card>
                        <br/>
                    </div>
                )

            })}
        </Container>
    );
}

export default withRouter(TaskOverview);
