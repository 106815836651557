import React from 'react';
import {Spinner} from "react-bootstrap";
import "./loading.css"

function Loading(props: any) {

    return (
        <div className="content">
            <Spinner animation="border" variant="primary"/>
        </div>
    );
}

export default Loading;
