import React, {useState} from 'react';
import {Redirect, withRouter} from "react-router-dom";
// @ts-ignore
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {convertToRaw, EditorState} from 'draft-js';
import "./Protocol.css"
import {gql, useMutation, useQuery} from "@apollo/client";
import Form from "react-bootstrap/Form";
import {Button, Modal, Table} from "react-bootstrap";
import Loading from "../../components/loading/Loading";

const options = {year: 'numeric', month: 'long', day: 'numeric'};

const GET_PROTOCOLS = gql`
    query protocols($project:ID!) {
        protocols(project: $project) {
            _id
            creationTime
            text
            titel
            author
        }
    }
`;

const CREATE_PROTOCOL = gql`
    mutation CreateProtocol($project:ID!, $text:String!, $titel: String!) {
        createProtocol(project: $project, text: $text, titel: $titel)
    }
`;

const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

const setState = (setFunc: any, value: any) => {
    setFunc(value);
};

function Protocol(props: any) {
    const {loading, error, data, refetch} = useQuery(GET_PROTOCOLS, {variables: {project: props.id}});

    const [createProtocol, {}] = useMutation(CREATE_PROTOCOL);

    const [showProtocol, setShowProtocol] = useState(false);
    const handleCloseProtocol = () => setShowProtocol(false);
    const handleShowProtocol = () => setShowProtocol(true);
    const handleSaveProtocol = (e: any) => {
        e.preventDefault();
        setShowProtocol(false);
        createProtocol({
            variables: {
                project: props.id,
                text: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
                titel: newProtocolTitel
            }
        }).then(r => {
            setNewProtocolTitel("");
            setNewProtocol("");
            refetch();
        });
    };

    const [newProtocol, setNewProtocol] = useState('');
    const [newProtocolTitel, setNewProtocolTitel] = useState('');

    //
    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <Redirect to="/projects"/>
    }

    return (
        <>
            <Button variant="outline-primary" onClick={handleShowProtocol} block>
                Neues Protokoll erstellen
            </Button>
            <br/>
            <Table striped hover>
                <thead>
                <tr>
                    <th>Titel</th>
                    <th>Erstellungsdatum</th>
                    <th>Ersteller</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {data.protocols.map((protocol: any) => {
                    const creationTime = new Date(parseInt(protocol.creationTime));
                    const timeFormatted = creationTime.toLocaleDateString('de-DE', options);
                    return (
                        <tr key={protocol.creationTime}>
                            <th>{protocol.titel}</th>
                            <td>{timeFormatted}</td>
                            <td>{protocol.author}</td>
                            <td><Button variant="primary" onClick={(e: any) =>
                                props.history.push("/report/" + protocol._id, {id: protocol._id, project: props.id})
                            }>Ansehen</Button>{' '}</td>
                        </tr>)
                })}
                </tbody>
            </Table>

            <Modal show={showProtocol} onHide={handleCloseProtocol}
                   size="lg">
                <Form onSubmit={handleSaveProtocol}>
                    <Modal.Header closeButton>
                        <Modal.Title>Neues Protokoll erstellen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Titel</Form.Label>
                            <Form.Control required type="text" defaultValue={newProtocolTitel}
                                          onInput={(e: any) => setInput(e, setNewProtocolTitel)}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProtocol}>
                            Schließen
                        </Button>
                        <Button variant="primary" type="submit">
                            Speichern
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    );
}

export default withRouter(Protocol);
