import React, {useState} from 'react';

import {gql, useMutation, useQuery} from "@apollo/client";
import Form from "react-bootstrap/Form";
import {Alert, Button, Modal, Table} from "react-bootstrap";
import Loading from "../../components/loading/Loading";
import {withApollo} from "@apollo/client/react/hoc";

const GET_COUPONS = gql`
    query Coupons($project:ID!) {
        coupons(project: $project) {
            id
            code
            amount
            description
            usage_count
            date_expires
        }
    }
`;

const CREATE_COUPON = gql`
    mutation CreateCoupon($code: String!, $discount: String!, $validUntil: String!, $description: String!, $project: ID!) {
        createCoupon(code:$code, discount: $discount, validUntil: $validUntil, description: $description, project: $project)
    }
`;

const DELETE_COUPON = gql`
    mutation DeleteCoupon($id:ID!) {
        deleteCoupon(id: $id)
    }
`;

const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

const setState = (setFunc: any, value: any) => {
    setFunc(value);
};

function Coupons(props: any) {
    const {loading, error, data, refetch} = useQuery(GET_COUPONS, {variables: {project: props.id}});

    const [createCoupon, {}] = useMutation(CREATE_COUPON);
    const [deleteCoupon, {}] = useMutation(DELETE_COUPON);

    const [showProtocol, setShowProtocol] = useState(false);
    const handleCloseProtocol = () => setShowProtocol(false);
    const handleShowProtocol = () => setShowProtocol(true);
    const handleSaveProtocol = (e: any) => {
        e.preventDefault();
        createCoupon({
            variables: {
                code,
                discount: discount.toString(),
                validUntil,
                project: props.id,
                description: description === "" ? "Projekt-ID: " + props.id : description
            }
        }).then(r => {
            if (r.data.createCoupon === "") {
                setShowProtocol(false);
                refetch();
                setCode("");
                setDiscount(0);
                setValidUntil("");
                setDescription("");
            } else {
                setCouponError(r.data.createCoupon)
            }
        })
    };

    const [code, setCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [validUntil, setValidUntil] = useState('');
    const [description, setDescription] = useState("");
    const [couponError, setCouponError] = useState(null);

    if (loading) {
        return <Loading/>
    }
    if (error) {
        // return <Redirect to="/projects"/>
    }

    return (
        <>
            <Button variant="outline-primary" onClick={handleShowProtocol} block>
                Neuen Coupon hinzufügen
            </Button>
            <br/>
            <Table striped hover>
                <thead>
                <tr>
                    <th>Code</th>
                    <th>Rabatt in €</th>
                    <th>Beschreibung</th>
                    <th>Gültig bis</th>
                    <th>Verwendungen</th>
                    <th></th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {data.coupons.map((e: any) => {
                    return (
                        <tr key={e.id}>
                            <th>{e.code}</th>
                            <th>{e.amount}</th>
                            <td>{e.description}</td>
                            <td>{e.date_expires ? new Date(e.date_expires).toLocaleDateString("de-DE") : "kein Ablaufdatum"}</td>
                            <td>{e.usage_count}</td>
                            <td style={{whiteSpace: 'pre-wrap'}}>{e.tel}</td>
                            <td><Button variant="outline-danger" onClick={(event: any) => {
                                deleteCoupon({
                                    variables: {
                                        id: e.id,
                                    }
                                }).then((r: any) => refetch())
                            }}>Entfernen</Button></td>
                        </tr>)
                })}
                </tbody>
            </Table>

            <Modal show={showProtocol} onHide={handleCloseProtocol}>
                <Form onSubmit={handleSaveProtocol}>
                    <Modal.Header closeButton>
                        <Modal.Title>Neuen Coupon hinzufügen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {couponError && <Alert variant="danger">
                            {couponError}
                        </Alert>}
                        <Form.Group>
                            <Form.Label>Code</Form.Label>
                            <Form.Control required type="text" defaultValue={code}
                                          onInput={(e: any) => setInput(e, setCode)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Rabatt in €</Form.Label>
                            <Form.Control type="number" defaultValue={discount}
                                          onInput={(e: any) => setInput(e, setDiscount)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Beschreibung</Form.Label>
                            <Form.Control type="text" defaultValue={description}
                                          onInput={(e: any) => setInput(e, setDescription)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Gültig bis</Form.Label>
                            <Form.Control type="date" defaultValue={validUntil}
                                          onInput={(e: any) => setInput(e, setValidUntil)}/>
                            <Form.Text className="text-muted">
                                Freilassen, um einen zeitlich unbegrenzt gültigen Coupon zu erstellen.
                            </Form.Text>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProtocol}>
                            Schließen
                        </Button>
                        <Button variant="primary" type="submit">
                            Speichern
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    );
}

export default withApollo(Coupons);
