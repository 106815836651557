import React, {useEffect, useState} from 'react';

import {gql, useMutation, useQuery} from "@apollo/client";
import Form from "react-bootstrap/Form";
import {Alert, Button, Card, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row} from "react-bootstrap";

import './BaseData.css';

import {Redirect} from 'react-router-dom';
import Loading from "../../components/loading/Loading";
import {withApollo} from "@apollo/client/react/hoc";
import {getEndTime, getStartTime} from "../courses/Courses";
import {fromUnixTime} from "date-fns";

const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

const GET_PROJECT = gql`
    query project($id:ID!) {
        project(id: $id) {
            _id
            id
            name
            institution
            address {
                street
                city
                state
                zip
            }
            creationTime
            notes
            laufzettel
            followUp
            firstDay
            secondDay
            meetingPoint
            tel
            email
            youtubeURL
            registrationOpen
        }
    }
`;

const GET_COURSES = gql`
    query Courses($project:ID!) {
        courses(project: $project) {
            _id
            date
            start
            end
            category
            processor
            subject
            note
            kursNr
        }
    }
`;

const GET_CITY = gql`
    query Zip($zip:String!) {
        zip(zip: $zip) {
            ort
            bundesland
        }
    }
`;

const CREATE_PROJECT = gql`
    mutation createProject($institution: String, $name: String, $address: InputAddress, $tel: String, $email: String, $laufzettel: String, $meetingPoint: String, $followUp: Int, $notes: String, $youtubeURL: String, $registrationOpen: Boolean, $_id: ID, $year: Int) {
        createProject(institution: $institution, name: $name, address: $address, tel: $tel, email: $email, laufzettel: $laufzettel, meetingPoint: $meetingPoint, followUp: $followUp, notes: $notes, youtubeURL: $youtubeURL, registrationOpen: $registrationOpen, _id: $_id, year: $year)
    }
`;

const GET_TERMINSCHEMAS = gql`
    {
        terminschemas {
            name
            institution
            zahlungsweise
            anmeldung
            _id
        }
    }
`;


const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

const setState = (setFunc: any, value: any) => {
    setFunc(value);
};

function BaseData(props: any) {
    const {loading, error, data, refetch} = useQuery(GET_PROJECT, {variables: {id: props.id}});
    const {loading: loadingCourses, error: errorCourses, data: dataCourses} = useQuery(GET_COURSES, {variables: {project: props.id}});
    const [createProject, {}] = useMutation(CREATE_PROJECT);
    const {loading: loadingTerminschema, error: errorTerminschema, data: dataTerminschema, refetch: refetchTerminschema} = useQuery(GET_TERMINSCHEMAS);

    const [name, setName] = useState("s");
    const [year, setYear] = useState(new Date().getFullYear());
    const [code, setCode] = useState("s");
    const [street, setStreet] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [geoState, setGeoState] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [institution, setInstitution] = useState('');
    const [laufzettel, setLaufzettel] = useState('');
    const [meetingPoint, setMeetingPoint] = useState('');
    const [followUp, setFollowUp] = useState('');
    const [notes, setNotes] = useState('');
    const [youtubeURL, setYouTubeURL] = useState('');
    const [registrationOpen, setRegistrationOpen] = useState(true);

    const [terminschema, setTerminschema] = useState('');

    const [showSpecialTimes, setShowSpecialTimes] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (!loading && !error && data) {
            setName(data.project.name || "");
            setCode(data.project.id || "");
            setStreet(data.project.address?.street || "");
            setZip(data.project.address?.zip || "");
            setCity(data.project.address?.city || "");
            setGeoState(data.project.address?.state || "");
            setTel(data.project.tel || "");
            setEmail(data.project.email || "");
            setInstitution(data.project.institution || "");
            setLaufzettel(data.project.laufzettel || "");
            setMeetingPoint(data.project.meetingPoint || "");
            setFollowUp(data.project.followUp || "");
            setNotes(data.project.notes || "");
            setYouTubeURL(data.project.youtubeURL || "")
            setRegistrationOpen(data.project.registrationOpen ?? true)
            if(data.project.creationTime) {
                setYear( fromUnixTime((data.project.creationTime / 1000)).getFullYear())
            }
            if (!loadingTerminschema && !errorTerminschema && dataTerminschema) {
                let set = false;
                dataTerminschema.terminschemas.forEach((schema: any) => {
                    if (data.project.institution === schema.institution && data.project.zahlungsweise === schema.zahlungsweise && data.project.anmeldung === schema.anmeldung) {
                        setTerminschema(schema.name);
                        set = true;
                    }
                });
                if (!set) {
                    setTerminschema("");
                }
            }
        }

    }, [loading, data, dataTerminschema]);


    if (loading || loadingCourses || loadingTerminschema) {
        return <Loading/>
    }
    if (error || errorCourses || errorTerminschema) {
        return <Redirect to="/projects"/>
    }

    if (!data.project) {
        return <Redirect to="/projects"/>
    }


    const cards: any = {};
    dataCourses.courses.forEach((task: any) => {
        if (!cards[task.date]) {
            cards[task.date] = [];
        }
        cards[task.date].push(task);
    });
    return (
        <>
            <Form onSubmit={(e: any) => {
                e.preventDefault();
                createProject({
                    variables: {
                        institution,
                        name,
                        address: {
                            street,
                            zip,
                            city,
                            state: geoState
                        },
                        tel,
                        email,
                        laufzettel,
                        meetingPoint,
                        followUp: parseInt(followUp),
                        notes,
                        youtubeURL,
                        registrationOpen,
                        year: Number(year),
                        _id: props.id
                    }
                }).then(() => {
                    refetch();
                    setShowError(false);
                    setShowSuccess(true);
                }).catch((e: any) => {
                    setShowSuccess(false);
                    setShowError(true);
                    setErrorMessage(e.toString());
                })
            }}>
                {showSuccess && <Alert variant="success">
                    Ihre Änderungen der Stammdaten wurden gespeichert.
                </Alert>}
                {showError && <Alert variant="danger">
                    Ihre Änderungen der Stammdaten konnten nicht gespeichert werden.
                    Bitte melden Sie folgenden Fehler:<br/>
                    {errorMessage}
                </Alert>}
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Label>Einrichtung</Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            value={institution}
                                            readOnly
                                        />

                                        <DropdownButton
                                            as={InputGroup.Append}
                                            variant="outline-secondary"
                                            title="Auswahl treffen"
                                            id="input-group-dropdown-2"
                                        >
                                            <Dropdown.Item
                                                onClick={(e: any) => setState(setInstitution, 'Kita')}>Kita</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={(e: any) => setState(setInstitution, 'Vorschule')}>Vorschule</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={(e: any) => setState(setInstitution, 'Grundschule')}>Grundschule</Dropdown.Item>
                                        </DropdownButton>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Jahr</Form.Label>
                                    <Form.Control type="number" value={year}
                                                  onChange={(e: any) => setInput(e, setYear)}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control value={name} onChange={(e: any) => setInput(e, setName)}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Projektkennung</Form.Label>
                                    <Form.Control value={code} readOnly/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Straße</Form.Label>
                                    <Form.Control value={street} onChange={(e: any) => setInput(e, setStreet)}/>
                                </Form.Group>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>PLZ / Ort</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control value={zip} onChange={(e: any) => {
                                        setInput(e, setZip);
                                        if (e.target.value.length === 5) {
                                            props.client.query({
                                                query: GET_CITY,
                                                variables: {zip: e.target.value}
                                            }).then((r: any) => {
                                                if (r.data.zip) {
                                                    setCity(r.data.zip.ort)
                                                    setGeoState(r.data.zip.bundesland)
                                                }
                                            })
                                        }
                                    }}/>
                                    <Form.Control value={city} onChange={(e: any) => setInput(e, setCity)}/>
                                </InputGroup>
                                <Form.Group>
                                    <Form.Label>Bundesland</Form.Label>
                                    <Form.Control value={geoState} onChange={(e: any) => setInput(e, setGeoState)}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Telefon</Form.Label>
                                    <Form.Control value={tel} onChange={(e: any) => setInput(e, setTel)}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            placeholder=""
                                            aria-describedby="basic-addon1"
                                            type="email"
                                            value={email}
                                            onChange={(e: any) => setInput(e, setEmail)}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text className="email" id="basic-addon2"
                                                             onClick={() => window.location.href = 'mailto:' + email || ''}>@</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                                <hr/>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Abweichende Zeiten anzeigen"
                            onClick={() => {
                                setShowSpecialTimes(!showSpecialTimes);
                            }}
                        />
                        <hr/>
                        {
                            Object.values(cards).map((course: any, k: number) => {
                                const date = new Date(parseInt(course[0].date));
                                const timeFormatted = date.toLocaleDateString('de-DE', options);
                                let c = 1;
                                let e = 1;
                                let i = 1;
                                return (
                                    <div key={k}>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>{timeFormatted}</Card.Title>
                                                <hr/>
                                                {course.map((entry: any, key: number) => {
                                                    let color = "";
                                                    if (entry.category === "Kurs") {
                                                        if (showSpecialTimes && ((c === 1 || c === 2) && getStartTime(data.project.institution, c) !== entry.start || getEndTime(data.project.institution, c) !== entry.end)) {
                                                            color = "3px solid #d9534f";
                                                        }
                                                    }
                                                    if (entry.category === "Elternveranstaltung") {
                                                        if (data.project.institution === "Grundschule" && data.project.firstDay !== entry.date) {
                                                            color = "3px solid #d9534f";
                                                        }
                                                        if (data.project.institution === "Kita" && data.project.secondDay !== entry.date) {
                                                            color = "3px solid #d9534f";
                                                        }
                                                    }
                                                    return (
                                                        <div key={entry._id}>
                                                            <Form.Group>
                                                                {/*<Form.Label>{entry.category === "Kurs" ? c++ : entry.category === "Elternveranstaltung" ? e++ : i++}. {entry.category}</Form.Label>*/}
                                                                <Form.Label>{entry.kursNr ? entry.category + " - Nr.: " + entry.kursNr : entry.category}</Form.Label>
                                                                <InputGroup>
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text
                                                                            id="basic-addon2">{entry.processor}</InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control type="text" style={{border: color}}
                                                                                  value={entry.start + " - " + entry.end}
                                                                                  readOnly
                                                                    />
                                                                </InputGroup>
                                                            </Form.Group>
                                                            <hr/>
                                                        </div>
                                                    )
                                                })}
                                                <Card.Text style={{whiteSpace: 'pre-wrap'}}>
                                                    {course.processor}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <br/>
                                    </div>
                                );
                            })
                        }


                    </Col>
                    <Col><Card>
                        <Card.Body>
                            <Form.Group controlId="exampleForm.ControlTextarea1234">
                                <Form.Label>Laufzettel</Form.Label>
                                <Form.Control as="textarea" rows={6} value={laufzettel}
                                              onChange={(e: any) => setInput(e, setLaufzettel)}
                                              style={{whiteSpace: 'pre-wrap'}}/>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlTextarea15345">
                                <Form.Label>Treffpunkt</Form.Label>
                                <Form.Control as="textarea" rows={2} value={meetingPoint}
                                              onChange={(e: any) => setInput(e, setMeetingPoint)}
                                              style={{whiteSpace: 'pre-wrap'}}/>
                            </Form.Group>
                            <hr/>
                            <Form.Group>
                                <Form.Label>Wiedervorlage</Form.Label>
                                <Form.Control type="number" value={followUp}
                                              onChange={(e: any) => setInput(e, setFollowUp)}/>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlTextarea15436">
                                <Form.Label>Notizen</Form.Label>
                                <Form.Control as="textarea" rows={10} value={notes}
                                              onChange={(e: any) => setInput(e, setNotes)}
                                              style={{whiteSpace: 'pre-wrap'}}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>YouTube-URL</Form.Label>
                                <Form.Control type="url" value={youtubeURL}
                                              onChange={(e: any) => setInput(e, setYouTubeURL)}/>
                            </Form.Group>
                            <hr/>
                            <Form.Check
                                type="switch"
                                id="reg-switch"
                                checked={registrationOpen}
                                onChange={() => {
                                    setRegistrationOpen(!registrationOpen);
                                }}
                                label="Projekt für Anmeldungen freigeben"
                            />

                        </Card.Body>
                    </Card>
                    </Col>
                </Row>
                <hr/>
                <Button variant="success" type="submit">Änderungen speichern</Button>
            </Form>
        </>
    );
}

export default withApollo(BaseData);
