import React, {useState} from 'react';

import {gql, useMutation, useQuery} from "@apollo/client";
import Form from "react-bootstrap/Form";
import {Alert, Button, InputGroup, Modal, Table} from "react-bootstrap";

import {Redirect} from 'react-router-dom';
import Loading from "../../components/loading/Loading";
import {withApollo} from "@apollo/client/react/hoc";

const options = {year: 'numeric', month: 'numeric', day: 'numeric'};

const GET_PARTICIPANTS = gql`
    query Participants($project:ID!) {
        participants(project: $project) {
            _id
            firstname
            lastname
            email
            tel
            course
            payed
            class
        }
    }
`;

const GET_COURSES = gql`
    query Courses($project:ID!) {
        courses(project: $project) {
            _id
            date
            start
            end
            category
            processor
            subject
            note
            kursNr
        }
    }
`;

const CREATE_PARTICIPANT = gql`
    mutation CREATE_PARTICIPANT($project:ID!, $firstname:String!, $lastname: String!, $email: String, $tel: String, $payed: Boolean, $course: ID, $class: String, $_id: ID) {
        createParticipant(project: $project, firstname: $firstname, lastname: $lastname, email: $email, tel: $tel, payed: $payed, course: $course, class: $class, _id: $_id)
    }
`;

const UPDATE_PAYMENT = gql`
    mutation UpdatePayment($_id:ID!, $payed: Boolean!) {
        updatePayment(_id: $_id, payed: $payed)
    }
`;


const UPDATE_COURSE = gql`
    mutation UpdateCourse($_id:ID!, $course: ID!) {
        updateCourse(_id: $_id, course: $course)
    }
`;


const DELETE_PARTICIPANT = gql`
    mutation DeleteParticipant($_id:ID!, $project: ID!) {
        deleteParticipant(_id: $_id, project: $project)
    }
`;

const SEND_MAIL = gql`
    mutation SendMail($id:ID!) {
        sendEmailWithYouTubeURL(id: $id)
    }
`;

const setInput = (e: React.ChangeEvent<HTMLInputElement>, setFunc: any) => {
    setFunc(e.target.value);
};

const setState = (setFunc: any, value: any) => {
    setFunc(value);
};

const paymentStatus = (v: string) => {
    return v === 'yes';
};

function Participants(props: any) {
    const {loading, error, data, refetch} = useQuery(GET_PARTICIPANTS, {variables: {project: props.id}});

    const {loading: loadingCourses, error: errorCourses, data: dataCourses} = useQuery(GET_COURSES, {variables: {project: props.id}});

    const [createProtocol, {}] = useMutation(CREATE_PARTICIPANT);
    const [updatePayment, {}] = useMutation(UPDATE_PAYMENT);
    const [deleteParticipant, {}] = useMutation(DELETE_PARTICIPANT);
    const [updateCourse, {}] = useMutation(UPDATE_COURSE);
    const [sendMail, {}] = useMutation(SEND_MAIL);

    const [showProtocol, setShowProtocol] = useState(false);
    const [showGroupSelection, setShowGroupSelection] = useState(false);
    const handleCloseProtocol = () => {
        if (id) {
            setEmail("");
            setFirstname("");
            setLastname("");
            setSClass("");
            setTel("");
            setPayed(false);
            setCourse("Keine Zuordnung");
            setID(null);
        }
        setShowProtocol(false)
    };
    const handleShowProtocol = () => setShowProtocol(true);
    const handleSaveProtocol = (e: any) => {
        e.preventDefault();
        setShowProtocol(false);
        if (id) {
            createProtocol({
                variables: {
                    project: props.id,
                    _id: id,
                    firstname,
                    lastname,
                    email,
                    tel,
                    payed,
                    course,
                    class: sclass
                }
            }).then(r => {
                refetch();
                setEmail("");
                setFirstname("");
                setLastname("");
                setSClass("");
                setTel("");
                setPayed(false);
                setCourse("Keine Zuordnung");
                setID(null);
            });
        } else {
            createProtocol({
                variables: {
                    project: props.id,
                    firstname,
                    lastname,
                    email,
                    tel,
                    payed,
                    course,
                    class: sclass
                }
            }).then(r => {
                refetch();
                setEmail("");
                setFirstname("");
                setLastname("");
                setSClass("");
                setTel("");
                setPayed(false);
                setCourse("Keine Zuordnung");
            });
        }
    };

    const [selectedParticipants, setSelectedParticipants] = useState(new Set());
    const [selectedGroups, setSelectedGroups] = useState(new Set());

    const [id, setID] = useState(null);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [sclass, setSClass] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [payed, setPayed] = useState(false);
    const [course, setCourse] = useState("Keine Zuordnung");

    const [message, setMessage] = useState('');
    const [nonValidEmails, setNonValidEmails] = useState([]);

    const [sortConfig, setSortConfig]: any = useState({key: "lastname", direction: "ascending"});

    const requestSort = (key: any) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    }

    if (loading || loadingCourses) {
        return <Loading/>
    }
    if (error || errorCourses) {
        return <Redirect to="/projects"/>
    }

    let participants = data.participants;

    const sortedProducts = [...participants];
    participants = sortedProducts.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const getArrows = (key: string) => {
        return sortConfig.key === key ?
            <span dangerouslySetInnerHTML={{__html: sortConfig.direction === "ascending" ? "&uarr;" : "&darr;"}}/> : ""
    }

    const formattedCourse = (entry: any) => {
        return `${entry.category} | ${entry.kursNr ? "Nr. " +
            entry.kursNr + " | "
            : ""}${new Date(Number(entry.date)).toLocaleDateString('de-DE', options)} |
        ${entry.start + " - " + entry.end} | ${entry.processor} | ${entry.subject}`
    }

    return (
        <>
            {message && <Alert variant="success">
                {message}
                {nonValidEmails.length !== 0 ? <> Folgende Adressen sind jedoch ungültig:<br/>
                    <br/>
                    <ul>
                        {nonValidEmails.map((nonValidEmail: string) => <li key={nonValidEmail}>{nonValidEmail}</li>)}
                    </ul>
                </> : null}
            </Alert>}
            <div className="row">
                <div className="col-6">
                    <Button variant="outline-primary" onClick={handleShowProtocol} block>
                        Neuen Kursteilnehmer hinzufügen
                    </Button>
                </div>
                <div className="col-6">
                    <Button variant="outline-primary"
                            onClick={() => {
                                sendMail({
                                    variables: {
                                        id: props.id,
                                    }
                                }).then(r => {
                                    setNonValidEmails(r.data.sendEmailWithYouTubeURL);
                                    setMessage("Es wurde eine E-Mail an alle Teilnehmer versendet.");
                                });
                            }}
                            disabled={participants.length === 0}
                            block>
                        E-Mail mit YouTube-Stream an alle Teilnehmer senden
                    </Button>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-6">
                    <Button variant="outline-primary" onClick={() => setShowGroupSelection(true)} block>
                        Gruppenselektion
                    </Button>
                </div>
                <div className="col-6">
                    <Button variant="outline-primary"
                            href={"mailto:Info@smart-team.de?bcc=" + Array.from(selectedParticipants).reduce((previousValue, currentValue) => previousValue + "," + currentValue, "")}
                            target="_blank"
                            disabled={selectedParticipants.size === 0}
                            block>
                        E-Mail an ausgewählte Teilnehmer senden (neues Fenster)
                    </Button>
                </div>
            </div>
            <br/>
            <Table striped hover>
                <thead>
                <tr>
                    <th></th>
                    <th onClick={() => requestSort('lastname')}>Nachname {getArrows("lastname")}</th>
                    <th onClick={() => requestSort('firstname')}>Vorname {getArrows("firstname")}</th>
                    <th onClick={() => requestSort('class')}>Klasse {getArrows("class")}</th>
                    <th onClick={() => requestSort('email')}>E-Mail {getArrows("email")}</th>
                    <th onClick={() => requestSort('tel')}>Handy {getArrows("tel")}</th>
                    <th>Kurs</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {participants.map((participant: any) => {
                    const pCourse = dataCourses.courses.find((e: any) => participant.course === e._id);
                    return (
                        <tr key={participant._id} style={{backgroundColor: participant.payed ? '#c3e6cb' : ''}}>
                            <td><InputGroup>
                                <InputGroup.Checkbox checked={selectedParticipants.has(participant.email)}
                                                     onChange={(e: any) => {
                                                         if (!e.target.checked) {
                                                             const newSet = new Set(selectedParticipants);
                                                             newSet.delete(participant.email);
                                                             setSelectedParticipants(newSet);
                                                         } else {
                                                             const newSet = new Set(selectedParticipants);
                                                             newSet.add(participant.email);
                                                             setSelectedParticipants(newSet);
                                                         }
                                                     }}/>
                            </InputGroup></td>
                            <th>{participant.lastname}</th>
                            <td>{participant.firstname}</td>
                            <td>{participant.class}</td>
                            <td>{participant.email}</td>
                            <td>{participant.tel}</td>
                            <td>
                                <Form.Group controlId={"exampleForm.ControlSelect" + participant._id}>
                                    <Form.Control as="select" value={pCourse ? pCourse._id : '-1'}
                                                  onChange={(e: any) => {
                                                      updateCourse({
                                                          variables: {
                                                              _id: participant._id,
                                                              course: e.target.value
                                                          }
                                                      }).then(() => refetch());
                                                  }}>
                                        <option value="-1">Keine Zuordnung</option>
                                        {dataCourses.courses.map((entry: any) =>
                                            <option value={entry._id}
                                                    key={entry._id}>{formattedCourse(entry)}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </td>
                            <td>
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <Button block variant="outline-danger" onClick={(e: any) => {
                                            deleteParticipant({
                                                variables: {
                                                    _id: participant._id,
                                                    project: props.id
                                                }
                                            }).then(() => {
                                                refetch();
                                            })
                                        }}>Entfernen</Button>
                                    </div>
                                    <div className="col-12 col-xl-6 mt-2 mt-xl-0">
                                        <Button block variant="outline-primary"
                                                onClick={(e: any) => {
                                                    setID(participant._id);
                                                    setEmail(participant.email);
                                                    setFirstname(participant.firstname);
                                                    setLastname(participant.lastname);
                                                    setTel(participant.tel);
                                                    setSClass(participant.class);
                                                    setPayed(participant.payed);
                                                    setCourse(participant.course);
                                                    handleShowProtocol()
                                                }}>Bearbeiten</Button>
                                    </div>
                                </div>


                            </td>
                        </tr>)
                })}
                </tbody>
            </Table>

            <Modal show={showProtocol} onHide={handleCloseProtocol}>
                <Form onSubmit={handleSaveProtocol}>
                    <Modal.Header closeButton>
                        <Modal.Title>{id ? "Teilnehmer bearbeiten" : "Neuen Teilnehmer hinzufügen"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Nachname</Form.Label>
                            <Form.Control required type="text" defaultValue={lastname}
                                          onInput={(e: any) => setInput(e, setLastname)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Vorname</Form.Label>
                            <Form.Control required type="text" defaultValue={firstname}
                                          onInput={(e: any) => setInput(e, setFirstname)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Klasse</Form.Label>
                            <Form.Control required type="text" defaultValue={sclass}
                                          onInput={(e: any) => setInput(e, setSClass)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" defaultValue={email}
                                          onInput={(e: any) => setInput(e, setEmail)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Handy</Form.Label>
                            <Form.Control type="text" defaultValue={tel} onInput={(e: any) => setInput(e, setTel)}/>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect2">
                            <Form.Label>Kurs</Form.Label>
                            <Form.Control as="select" defaultValue={course || 'Keine Zuordnung'} onChange={(e: any) =>
                                setCourse(e.target.value)
                            }>
                                <option>Keine Zuordnung</option>
                                {dataCourses.courses.map((entry: any) =>
                                    <option value={entry._id}
                                            key={entry._id}>{formattedCourse(entry)}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect3">
                            <Form.Label>Bezahlstatus</Form.Label>
                            <Form.Control as="select" defaultValue={payed ? "yes" : "no"} required
                                          onChange={(e: any) => {
                                              setPayed(paymentStatus(e.target.value))
                                          }}
                            >
                                <option value="no">Nicht bezahlt
                                </option>
                                <option value="yes">Bezahlt
                                </option>
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProtocol}>
                            Schließen
                        </Button>
                        <Button variant="primary" type="submit">
                            Speichern
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showGroupSelection} onHide={() => setShowGroupSelection(false)}>
                <Form onSubmit={handleSaveProtocol}>
                    <Modal.Header closeButton>
                        <Modal.Title>Gruppenselektion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {dataCourses.courses.map((entry: any, index: number) =>
                                <div className="col-12 mt-3" key={index}>
                                    <Button
                                        block
                                        variant={selectedGroups.has(index) ? "primary" : "outline-primary"}
                                        onClick={() => {
                                            const newGroupSet = new Set(selectedGroups);
                                            const newSet = new Set(selectedParticipants);
                                            if (selectedGroups.has(index)) {
                                                newGroupSet.delete(index);
                                                const parts = participants.forEach((participant: any) => {
                                                    if (participant.course === entry._id) {
                                                        newSet.delete(participant.email);
                                                    }
                                                });
                                            } else {
                                                newGroupSet.add(index);
                                                const parts = participants.forEach((participant: any) => {
                                                    if (participant.course === entry._id) {
                                                        newSet.add(participant.email);
                                                    }
                                                });
                                            }
                                            setSelectedGroups(newGroupSet);
                                            setSelectedParticipants(newSet);

                                        }}>
                                        {formattedCourse(entry)}
                                    </Button>
                                </div>
                            )}
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowGroupSelection(false)}>
                            Schließen
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    );
}

export default withApollo(Participants);
