import React, {useContext} from 'react';

import {gql} from "@apollo/client";

import './Dashboard.css';
import {LoggedIn} from "../../App";
import Topbar from "../../components/navbar/Topbar";


const GENERATE_JWT = gql`
    mutation GcenerateJWT($email:String!, $password: String!) {
        generateJWT(email: $email, password: $password)
    }
`;

function Dashboard(props: any) {
    const {loggedIn, setLoggedIn} = useContext(LoggedIn);

    const cl = () => {
        setLoggedIn(false);
    };

    return (
        <div>
            <Topbar/>
            {props.children}
        </div>
    );
}

export default Dashboard;
